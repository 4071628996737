import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { FavoriteService } from './favorite-service.service';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { LocalStorageService } from '../services/local-storage.service';
import { environment } from '../../environments/environment';
import { EncryptService } from './encrypt.service';

@Injectable({
  providedIn: 'root'
})
export class OTTService {

  AllCanales: any[] = [];
  AllCategoria: any[] = [];
  private ContadorPeticiones: number = 10;
  StatusService: string = ''
  HaveFibexPlay: boolean
  Package_Fibex: any
  Id_Package: any

  constructor(
    public http: HttpClient,
    public favoriteSer: FavoriteService,
    private _geolocation: Geolocation,
    private _localStorageService: LocalStorageService,
    private _EncryptService: EncryptService
  ) {
    this.getUser();
  }

  getUser() {
    let userInfo = this._localStorageService.get('user') || null
    if (userInfo !== null) {
      this.GetCanales2(userInfo.identidad, "false")
    }
  }

  GetCanales() {
    return new Promise(async (resolve: any, reject: any) => {
      if (this.AllCanales.length == 0 || this.ContadorPeticiones >= 10) {
        this.ContadorPeticiones = 0
        this.LoadData().then(data => {
          resolve({ Cat: this.AllCategoria, Can: this.AllCanales })
        })
          .catch(error => { console.error(error); });
      } else {
        this.ContadorPeticiones++;
        resolve({ Cat: this.AllCategoria, Can: this.AllCanales })
      }
    })
  }

  getChannelsForInfo() {
    return new Promise(async (resolve: any, reject: any) => {
      try {

        const headers: any = {
          'method': this._EncryptService.EncryptSSL("GetChanels"),
          'token': this._EncryptService.EncryptSSL(environment.TokenSSL),
          'platform': this._EncryptService.EncryptSSL("FibexPlay"),
        }
        
        this.http.get(`${environment.URLApiSSL}FibexPlay`, { headers }).subscribe((ResGrilla: any) => {
          resolve(ResGrilla)
        }, error => {
          console.error(error.message);
          reject(error)
        })

      } catch (error) {
        console.error(error.message);
        reject(error)
      }
    })
  }

  GetCanales2(Cedula: any, FueraRed: any) {
    return new Promise(async (resolve: any, reject: any) => {
      this.ContadorPeticiones = 0
      this.LoadData2(Cedula, FueraRed).then(data => {
        resolve({ Cat: this.AllCategoria, Can: this.AllCanales, status: this.StatusService, FibexPlay: this.HaveFibexPlay, Package: this.Package_Fibex, Id_Package: this.Id_Package })
      })
        .catch(error => {
          console.error(error.message);
        });
    })
  }
  public returnCanales;
  GetOnLyCanalesCAT(idCat: number, index?) {

    if (idCat === 99) {
      return this.AllCanales.filter((item: any) => item.favorite);
    }

    if (idCat === 0) {
      const chunkSize = 30;
      if (index) {
        for (let i = index; i < index + 30; i += chunkSize) {
          const chunk = this.AllCanales.slice(i, i + chunkSize);
          this.returnCanales = chunk;
          // do whatever
        }
      }
      else {
        for (let i = index ? index : 0; i < 30; i += chunkSize) {
          const chunk = this.AllCanales.slice(i, i + chunkSize);
          this.returnCanales = chunk;
          // console.log(this.returnCanales)
          // do whatever
        }
      }
      return this.AllCanales;
    }

    // return this.AllCanales.filter(c => c.category_id == idCat);
    if (idCat != 99 && idCat != 0) {
      let canalesFiltrados: any
      const chunkSize = 25;
      if (index) {
        for (let i = index; i < index + 30; i += chunkSize) {
          const chunk = this.AllCanales.filter(c => c.category_id == idCat)
          // .slice(i, i + chunkSize);
          canalesFiltrados = chunk;
          // do whatever
        }
      }
      else {
        for (let i = index ? index : 0; i < 30; i += chunkSize) {
          const chunk = this.AllCanales.filter(c => c.category_id == idCat)
          // .slice(i, i + chunkSize);
          canalesFiltrados = chunk;
          // do whatever
        }
      }
      return canalesFiltrados;
    }
  }
  mapFavorites(canales: any) {
    const favorites = this.favoriteSer.getFavorites();
    return canales.map((item: any) => favorites.includes(item.id) ? { ...item, favorite: true } : { ...item, favorite: false });
  }

  getAllCanales() {
    return this.AllCanales;
  }

  addFavoritoToCanales(id: string) {
    const markedAsFavorite = this.favoriteSer.setFavorite(id);
    this.AllCanales = this.AllCanales.map((item: any) => item.id === id ? { ...item, favorite: markedAsFavorite } : item);
  }

  LoadData() {
    return new Promise(async (resolve: any, reject: any) => {
      try {
        const Canales = await this.LoadCanales();
        const Categorias = await this.LoadCategorias();
        this.AllCanales = <any[]>this.mapFavorites(Canales);
        this.AllCategoria = <any[]>Categorias;
        resolve(true);
      } catch (error) {
        reject(error)
      }
    })

  }

  LoadData2(Cedula: any, FueraRed: any) {
    return new Promise(async (resolve: any, reject: any) => {
      try {
        const headers: any = {
          'method': this._EncryptService.EncryptSSL("Canales"),
          'token': this._EncryptService.EncryptSSL(environment.TokenSSL),
          'platform': this._EncryptService.EncryptSSL("FibexPLay"),
          'cedula': this._EncryptService.EncryptSSL(Cedula),
          'lic': this._EncryptService.EncryptSSL(environment.Lic),
          'fuerared': this._EncryptService.EncryptSSL(`${FueraRed ? false : true}`)
        }
        this.http.get(`${environment.URLApiSSL}FibexPlay`, { headers }).subscribe((Res: any) => {
          this.AllCanales = <any[]>this.mapFavorites(Res.Canales);
          this.AllCategoria = <any[]>Res.Categorias;
          this.StatusService = Res.StatusServicio
          this.HaveFibexPlay = Res.FibexPlay
          this.Package_Fibex = Res.Package
          this.Id_Package = Res.Id_Package
          resolve(true);
        }, error => {
          console.error(error.message);
          reject(error)
        })

      } catch (error) {
        console.error(error.message);
        reject(error)
      }
    })

  }



  LoadCanales() {
    return new Promise(async (resolve: any, reject: any) => {
      try {
        this._geolocation.getCurrentPosition().then((data: any) => {/* console.log(data) */ }).catch(error => console.error(error))
        const url = "https://apiott.thomas-talk.me/api/find-all-info/tbl_tv_channel"
        this.http.get(url).subscribe(data => {
          resolve(data);

        }, error => {
          reject(error)
          console.error(error);
        });

      } catch (error) {
        reject(error)
      }
    })
  }

  LoadCategorias() {
    return new Promise(async (resolve: any, reject: any) => {
      try {

        const url = "https://apiott.thomas-talk.me/api/find-all-info/tbl_tv_categories"
        this.http.get(url).subscribe(data => {
          resolve(data);


        }, error => {
          resolve(error)
          console.error(error);
        });


      } catch (error) {
        reject(error)
      }
    })
  }



}
