import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-channels',
  templateUrl: './channels.component.html',
  styleUrls: ['./channels.component.scss'],
})
export class ChannelsComponent implements OnInit {
  @Input() data: any;

  constructor(
    private modalCtrl: ModalController,
  ) { }

  ngOnInit() { }

  closeModal() {
    this.modalCtrl.dismiss("cancel");
  }

}

