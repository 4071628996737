import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './services/user/auth.guard';
import { ProtectedRoutesGuard } from './services/user/protectedRoutes.guard';

const routes: Routes = [
  { path: 'home', loadChildren: () => import('./pages/local-weather/local-weather.module').then(m => m.LocalWeatherPageModule), canActivate: [ProtectedRoutesGuard]},
  { path: '', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule), canActivate: [AuthGuard] },
  { path: 'register', loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterPageModule), canActivate: [AuthGuard] },
  { path: 'favorites', loadChildren: () => import('./pages/favorites/favorites.module').then(m => m.FavoritesPageModule), canActivate: [ProtectedRoutesGuard]  },
  { path: 'NewUpdate', loadChildren: () => import('./pages/new-update/new-update.module').then(m => m.NewUpdatePageModule) },
  {
    path: 'video-player',
    loadChildren: () => import('./pages/modal/video-player/video-player.module').then( m => m.VideoPlayerPageModule),
    canActivate: [ProtectedRoutesGuard]
  }, 
  {
    path: 'notification-modal',
    loadChildren: () => import('./pages/modal/notification-modal/notification-modal.module').then( m => m.NotificationModalPageModule)
  },
  
  {
    path: 'pin-registration',
    loadChildren: () => import('./pages/modal/pin-registration/pin-registration.module').then( m => m.PinRegistrationPageModule)
  },
  {
    path: 'pin-validation',
    loadChildren: () => import('./pages/modal/pin-validation/pin-validation.module').then( m => m.PinValidationPageModule)
  },
  {
    path: 'grilla-programacion',
    loadChildren: () => import('./pages/modal/grilla-programacion/grilla-programacion.module').then( m => m.GrillaProgramacionPageModule),
    canActivate: [ProtectedRoutesGuard]
  },
  {
    path: 'grilla',
    loadChildren: () => import('./shared/grilla/grilla.module').then( m => m.GrillaModule)
  },
  {
    path: 'update-password',
    loadChildren: () => import('./pages/modal/update-password/update-password.module').then( m => m.UpdatePasswordPageModule)
  },
  {
    path: 'register2',
    loadChildren: () => import('./pages/register2/register2.module').then( m => m.Register2PageModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
