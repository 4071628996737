import { Component, OnInit, ViewChild } from '@angular/core';

import { Platform, NavController, MenuController, ModalController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { TranslateProvider } from './providers/translate/translate.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { UserAuthenticationService } from './services/user-authentication.service';
import { GetInfoDeviceService } from './services/get-info-device.service';
import { ConsultasService } from './services/consultas.service';
import { Pages } from './interfaces/pages';
import { SubscriptionGraphqlService } from "./services/subscription-graphql.service";
import { OptionMenu } from './providers';
import gql from 'graphql-tag';
import { GuiaProgramacionService } from './services/guia-programacion.service';
import { LocalStorageService } from './services/local-storage.service';
import { url } from 'inspector';
import { VerifyInternetStatusService } from './services/verify-internet-status.service';
// import function to register Swiper custom elements
import * as swiper from "swiper";
// register Swiper custom elements

// import "./lib/controls";
import Controls from './lib/controlsv2';
import { ActivationStart, Router, RouterOutlet } from '@angular/router';

const control = new Controls()
setTimeout(() => control.Register(), 2000);


/**
 * Main Wrap App Component with starting methods
 *
 * @export
 * @class AppComponent
 */
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  VersionApp = environment.version
  ShowMenu: Boolean = true;

  @ViewChild('outlet', {static: true}) outlet: RouterOutlet;

  /**
   * Creates an Array instance with <Pages> interface that receives all menu list.
   *
   * @type {Array<Pages>}
   * @memberof AppComponent
   */
  public appPages: Array<Pages>;
  public user = null;
  /**
   * Creates an instance of AppComponent.
   * @param {Platform} platform
   * @param {SplashScreen} splashScreen
   * @param {StatusBar} statusBar
   * @param {TranslateProvider} translate
   * @param {TranslateService} translateService
   * @param {NavController} navCtrl
   * @memberof AppComponent
   */
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private translate: TranslateProvider,
    private translateService: TranslateService,
    public navCtrl: NavController,
    private athentication: UserAuthenticationService,
    public menu: MenuController,
    private consultService: ConsultasService,
    private guia: GuiaProgramacionService,
    private _SubscriptionGraphqlService: SubscriptionGraphqlService,
    private _localStorageService: LocalStorageService,
    private _internetService: VerifyInternetStatusService,
    public modalCtrl: ModalController,
    private router: Router
  ) {

    this.appPages = OptionMenu;
    this._internetService.initService();
    this.initializeApp();
    this.getUserData();

    setTimeout(() => {  // La idea es dejar que cargue todo para luego cargar la Guia
      this.guia.loadGuia();
    }, 5000); 
    
  }
  /**
   * Method that starts all Cordova and Factories
   *
   * @memberof AppComponent
   */
  initializeApp() {
    try {
      this.platform.ready().then(() => {
        this.statusBar.styleDefault();
        setTimeout(() => {
          this.splashScreen.hide();
        }, 1000);
        // Set language of the app.
        this.translateService.setDefaultLang(environment.language);
        this.translateService.use(environment.language);
        this.translateService.getTranslation(environment.language).subscribe(translations => {
          this.translate.setTranslations(translations);
        });
      }).catch(() => {
        // Set language of the app.
        this.translateService.setDefaultLang(environment.language);
        this.translateService.use(environment.language);
        this.translateService.getTranslation(environment.language).subscribe(translations => {
          this.translate.setTranslations(translations);
        });
      });

    } catch (error) {
      console.error(error)
    }
  }
  /**
   * Navigate to Edit Profile Page
   *
   * @memberof AppComponent
   */

  goToEditProgile() {
    this.navCtrl.navigateForward('edit-profile');
    this.closeMenu();
  }
  /**
   * Logout Method
   *
   * @memberof AppComponent
   */

  ngOnInit(): void {

    this.router.events.subscribe(e => {
      if (e instanceof ActivationStart && e.snapshot.outlet === "errorpage")
            this.outlet.deactivate();
    });

    try {

      if (this.user) {
        this.consultService.ServerDisponibles(this.user.identidad).then((ResServer: any) => {
          if (Object.keys(ResServer).length > 0) {

            environment.ApiGraphQl = ResServer.ApiGraphQl
            environment.UrlSubscriptions = ResServer.UrlSubscriptions
            environment.apiChatBot = ResServer.apiChatBot
            environment.apiMaps = ResServer.apiMaps
            environment.apiPhP = ResServer.apiPhP

          }
        }).catch(error => console.error(error))

        /* this.consultService.VersionControl(this.user.identidad, this.VersionApp).then((res: any) => {
          if (res && res.url) {
            this.navCtrl.navigateForward('NewUpdate');
          }
        }) */
      }

      /* let posFibexPlay = this.appPages.findIndex((DMenu: any) => DMenu.title === "Fibex Play")
      this.appPages[posFibexPlay].show = this.user.fibex_play */


    } catch (error) {
      console.error(error)
    }

  }


  async closeMenu() {
    if (await this.menu.isOpen()) {
      await this.menu.close();
      await this.menu.enable(false);
    }
  }

  getUserData() {
    try {
      this.user = this.athentication.getUser();
      this.athentication.userState.subscribe((userData: any) => this.user = userData);
    } catch (error) {
      console.error(error)
    }
  }

  logout() {
    this.athentication.logout();
    this.navCtrl.navigateRoot('login');
  }

}
