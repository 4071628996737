import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment'
import { EncryptService } from './encrypt.service';

@Injectable({
  providedIn: 'root'
})
export class ConsultasService {
  public UrlUpdate: any
  AllCDN: any = []
  public EventChangePlan: EventEmitter<any> = new EventEmitter();

  constructor(
    public http: HttpClient,
    private _EncryptService: EncryptService
  ) { }

  EmitChengePlan(Data: any) { this.EventChangePlan.emit(Data) }

  ServerDisponibles(Cedula: any) {
    return new Promise(async (resolve: any, reject: any) => {
      try {
        const headers: any = {
          'method': this._EncryptService.EncryptSSL("ServerFree"),
          'token': this._EncryptService.EncryptSSL(environment.TokenSSL),
          'platform': this._EncryptService.EncryptSSL("FibexPLay"),
          'id': this._EncryptService.EncryptSSL(Cedula)
        }
        this.http.get(environment.URLApiSSL + `Consultas`, { headers }).subscribe((data: any) => {
          resolve(data)
        })

      } catch (error) {
        reject(error)
      }
    })
  }

  GetSaldoCedula(Cedula: any) {
    return new Promise(async (resolve: any, reject: any) => {
      const headers: any = {
        'method': this._EncryptService.EncryptSSL("SaldoCe"),
        'token': this._EncryptService.EncryptSSL(environment.TokenSSL),
        'platform': this._EncryptService.EncryptSSL("FibexPLay"),
        'id': this._EncryptService.EncryptSSL(Cedula),
        'lic': this._EncryptService.EncryptSSL(environment.Lic)
      }
      this.http.get(environment.URLApiSSL + `SAE`, { headers }).subscribe(data => {
        const SaldoD = JSON.parse(data[0])

        if (SaldoD.data.info.length > 0) {
          resolve(SaldoD.data.info)
        } else {
          resolve(null)
        }

      }, error => {
        console.error(error);
        reject(error);
      });
    })
  }

  GetSaldoContrato(NroContrato: any) {
    return new Promise(async (resolve: any, reject: any) => {
      try {
        const headers: any = {
          'method': this._EncryptService.EncryptSSL("SaldoCo"),
          'token': this._EncryptService.EncryptSSL(environment.TokenSSL),
          'platform': this._EncryptService.EncryptSSL("FibexPLay"),
          'id': this._EncryptService.EncryptSSL(NroContrato),
          'lic': this._EncryptService.EncryptSSL(environment.Lic)
        }
        this.http.get(environment.URLApiSSL + `SAE`, { headers }).subscribe(data => {
          const DataSaldo = JSON.parse(data[0])

          if (DataSaldo.data.info.length > 0) {
            resolve(DataSaldo.data.info[0])
          } else {
            resolve(null)
          }

        }, error => {
          console.error(error);
        });


      } catch (error) {
        reject(error)
      }
    })
  }

  GetCDNs() {
    return new Promise(async (resolve: any, reject: any) => {
      try {
        const headers: any = {
          'method': this._EncryptService.EncryptSSL("GetCDN"),
          'token': this._EncryptService.EncryptSSL(environment.TokenSSL),
          'platform': this._EncryptService.EncryptSSL("FibexPLay"),
        }
        this.http.get(`${environment.URLApiSSL}FibexPlay`, { headers }).subscribe((Res: any) => {
          const DataFiltrada = Res.filter((FCDN: any) => FCDN.Url != "")
          this.AllCDN = DataFiltrada
          resolve(DataFiltrada)
        }, error => {
          reject(error)
        });

      } catch (error) {
        reject(error)
      }
    })
  }

  GetCDNsFueraRed() {
    return new Promise(async (resolve: any, reject: any) => {
      try {
        const headers: any = {
          'method': this._EncryptService.EncryptSSL("GetCDNFueraRed"),
          'token': this._EncryptService.EncryptSSL(environment.TokenSSL),
          'platform': this._EncryptService.EncryptSSL("FibexPLay"),
        }
        this.http.get(`${environment.URLApiSSL}FibexPlay`, { headers }).subscribe((Res: any) => {
          resolve(Res)
        }, error => {
          reject(error)
        });

      } catch (error) {
        reject(error)
      }
    })
  }

  InfoContact() {
    return new Promise(async (resolve: any, reject: any) => {
      try {

        const DataQuery = {
          query: `
            query {
              InfoContact(
                token:"${environment.Token}" 
                lic:"${environment.Lic}"
              ){
                lic
                Content
              }
            }`,
        }

        this.http.post(environment.ApiGraphQl, DataQuery).subscribe((response: any) => {

          resolve(response.data.InfoContact)

        }, (error) => {
          reject(error);
        });


      } catch (error) {
        console.error(error)
      }
    })
  }

  InfoEquiposClientes(Cedula: any) {
    return new Promise<any[]>(async (resolve: any, reject: any) => {
      try {
        const headers: any = {
          'method': this._EncryptService.EncryptSSL("InfoEquipos"),
          'token': this._EncryptService.EncryptSSL(environment.TokenSSL),
          'platform': this._EncryptService.EncryptSSL("FibexPLay"),
          'id': this._EncryptService.EncryptSSL(Cedula),
          'lic': this._EncryptService.EncryptSSL(environment.Lic)
        }
        this.http.get(environment.URLApiSSL + `SAE`, { headers }).subscribe(data => {
          const Info = JSON.parse(data[0])
          resolve(Info.data.info)
        })

      } catch (error) {
        reject(error)
      }
    })
  }

  GetFacturasCliente(NroContrato: any) {
    return new Promise<any>(async (resolve: any, reject: any) => {
      try {
        const headers: any = {
          'method': this._EncryptService.EncryptSSL("FacturaEdoCuentaCo"),
          'token': this._EncryptService.EncryptSSL(environment.TokenSSL),
          'platform': this._EncryptService.EncryptSSL("FibexPLay"),
          'id': this._EncryptService.EncryptSSL(NroContrato),
          'lic': this._EncryptService.EncryptSSL(environment.Lic)
        }
        this.http.get(environment.URLApiSSL + `SAE`, { headers }).subscribe(data => {
          const Info = JSON.parse(data[0])
          resolve(Info.data.info)
        })

      } catch (error) {
        reject(error)
      }
    })
  }

  TurBoUpgrade(Data: any) {
    return new Promise(async (resolve: any, reject: any) => {
      try {

        const DataQuery = {
          query: `
          query {
            UpgrateTurboClient(
              token:"${environment.Token}"
              Data:{
                Cedula:"${Data.Cedula}"
                idDevice:"${Data.idDevice}"
                Accion:"${Data.Accion}"
                OldPlan:"${Data.OldPlan}"
                NewPlan:"${Data.NewPlan}"
                Status:${Data.Status}
                Email:"${Data.Email}"
              })
            }`,
        }

        this.http.post(environment.ApiGraphQl, DataQuery).subscribe((response: any) => {

          resolve(response.data.UpgrateTurboClient)

        }, (error) => {
          reject(error);
        });


      } catch (error) {
        console.error(error)
      }
    })
  }

  NewProspecto(Data: any, Mensaje: any) {
    return new Promise(async (resolve: any, reject: any) => {
      try {

        const DataQuery = {
          query: `
          query {
            ClientProspecto(
              token:"${environment.Token}"
              Data:{
                tipoCliente:"${Data.tipoCliente}"
                fullName:"${Data.fullName}"
                identidad:"${Data.identidad}"
                email:"${Data.email}"
                phone:"${Data.phone}"
                ciudad:"${Data.ciudad}"
                zona:"${Data.zona}"
                direccion:"${Data.direccion}"
                latitude:${Data.latitude}
                longitude:${Data.longitude}
              }
            Message:"${Mensaje}")
          }`,
        }

        this.http.post(environment.ApiGraphQl, DataQuery).subscribe((response: any) => {

          resolve(response.data.ClientProspecto)

        }, (error) => {
          reject(error);
        });


      } catch (error) {
        reject(error)
      }
    })

  }

  SolicitudPermisoTv(Data: any) {
    return new Promise(async (resolve: any, reject: any) => {
      try {

        const DataQuery = {
          query: `
          mutation{
            SolicitudPermisoTv(
              token:"${environment.Token}"
              Data:{
                IdDevice:"${Data.idDevice}"
                Documento:"${Data.Documento}"
                Canal:"${Data.Canal}"
                Url:"${Data.Url}"
                Plataforma:"${Data.Plataforma}"
                IP:"${Data.IP || ''}"
              }
            ){
              Permiso
              Publicidad
            }
          }`,
        }

        this.http.post(environment.ApiGraphQl, DataQuery).subscribe((response: any) => {

          resolve(response.data.SolicitudPermisoTv)

        }, (error) => {
          reject(error);
        });

      } catch (error) {
        reject(error)
      }
    })
  }

  ControlPermisoTv(Data: any) {
    return new Promise(async (resolve: any, reject: any) => {
      try {

        const DataQuery = {
          query: `
          query{
            PermisoTv(
              token:"${environment.Token}"
              Data:{
                IdDevice:"${Data.idDevice}"
                Documento:"${Data.Documento}"
                Canal:"${Data.Canal}"
                Plataforma:"${Data.Plataforma}"
                IP:"${Data.IP || ''}"
              }
            ){
              Permiso
              Publicidad
            }
          }`,
        }

        this.http.post(environment.ApiGraphQl, DataQuery).subscribe((response: any) => {

          resolve(response.data.PermisoTv)

        }, (error) => {
          reject(error);
        });

      } catch (error) {
        reject(error)
      }
    })
  }

  DeleteDevice(Data: any) {
    return new Promise(async (resolve: any, reject: any) => {
      try {

        const DataQuery = {
          query: `
          mutation{
            DeleteDevice(
              token:"${environment.Token}"
              Data:{
                IdDevice:"${Data.idDevice}"
                Documento:"${Data.Documento}"
                Canal:"${Data.Canal}"
                Plataforma:"${Data.Plataforma}"
                IP:"${Data.IP || ''}"
              }
            )
          }`,
        }

        this.http.post(environment.ApiGraphQl, DataQuery).subscribe((response: any) => {

          resolve(response.data.DeleteDevice)

        }, (error) => {
          reject(error);
        });

      } catch (error) {
        reject(error)
      }
    })
  }

  VersionControl(Cedula: any, version: any) {
    return new Promise(async (resolve: any, reject: any) => {
      try {
        const headers: any = {
          'method': this._EncryptService.EncryptSSL("ControlVFibexPlay"),
          'token': this._EncryptService.EncryptSSL(environment.TokenSSL),
          'platform': this._EncryptService.EncryptSSL("FibexPLay"),
          'cedula': this._EncryptService.EncryptSSL(Cedula),
          'version': this._EncryptService.EncryptSSL(version)
        }
        this.http.get(environment.URLApiSSL + `Consultas`, { headers }).subscribe((data: any) => {
          if (Cedula === "20215281") {
            //no verifico version
            const DataP = { url: '' }
            this.UrlUpdate = DataP.url
            resolve(DataP)
          } else {
            //Verifico la version
            this.UrlUpdate = data.url
            resolve(data)
          }
        })

      } catch (error) {
        reject(error)
      }
    })
  }

  GetMenu() {
    return new Promise(async (resolve: any, reject: any) => {
      try {
        const headers: any = {
          'method': this._EncryptService.EncryptSSL("MenuApp"),
          'token': this._EncryptService.EncryptSSL(environment.TokenSSL),
          'platform': this._EncryptService.EncryptSSL("FibexPLay")
        }
        this.http.get(environment.URLApiSSL + `Consultas`, { headers }).subscribe(data => {
          resolve(data)
        })

      } catch (error) {
        reject(error)
      }
    })
  }

  ListService() {
    return new Promise(async (resolve: any, reject: any) => {
      try {
        const headers: any = {
          'method': this._EncryptService.EncryptSSL("InfoServices"),
          'token': this._EncryptService.EncryptSSL(environment.TokenSSL),
          'platform': this._EncryptService.EncryptSSL("FibexPLay"),
          'lic': this._EncryptService.EncryptSSL(environment.Lic)
        }
        this.http.get(environment.URLApiSSL + `Consultas`, { headers }).subscribe(data => {
          resolve(data)
        })

      } catch (error) {
        reject(error)
      }
    })
  }

  GetIpDevice() {
    return new Promise(async (resolve: any, reject: any) => {
      try {

        this.http.get("https://api.ipify.org?format=json").subscribe((data: any) => {
          resolve(data.ip)
        }, (error: any) => {
          reject(error)
        })

      } catch (error) {
        reject(error)
      }
    })

  }

  InsertChat(DataChat: any) {
    return new Promise(async (resolve: any, reject: any) => {
      try {

        const DataMutaion = {
          query: `
          mutation{
            CreateChat(
              Chat:{
                idEmpresa:"${DataChat.idEmpresa}"
                phone:"${DataChat.phone}"
                idConversacion:"${DataChat.idConversacion}"
                idmessage:"${DataChat.idmessage}"
                timestamp:${DataChat.timestamp}
                me_to:"${DataChat.me_to}"
                contentType:"${DataChat.contentType}"
                content:"${DataChat.content}"
                TimeRead:"${DataChat.TimeRead}"
                status:${DataChat.status}
                statusc:${DataChat.statusc}
                readmsg:${DataChat.readmsg}
                operador:"${DataChat.operador}"
                lic:"${DataChat.lic}"
                etiqueta:"${DataChat.etiqueta}"
              }
            ){
              idEmpresa
              phone
              idConversacion
              idmessage
              timestamp
              me_to
              contentType
              content
              TimeRead
              status
              statusc
              readmsg
              etiqueta
              operador
              lic
              tag
              DatePc
              priority
            }
          }`,
        }

        this.http.post(environment.ApiGraphQl, DataMutaion).subscribe((response: any) => {

          resolve(response.data.CreateChat)

        }, (error) => {
          console.error(error)
          reject(error);
        });


      } catch (error) {
        console.error(error)
        reject(error)
      }
    })
  }

  ConsultaChat(Phone: any) {
    return new Promise(async (resolve: any, reject: any) => {
      try {

        const DataQuery = {
          query: `
          query{
            ChatByPhoneApp(
              token:"${environment.Token}" 
              phone:"${Phone}"
              lic:"${environment.Lic}"
            ){
                idEmpresa
                phone
                idConversacion
                idmessage
                timestamp
                me_to
                contentType
                content
                TimeRead
                status
                statusc
                readmsg
                operador
                lic
                etiqueta
                priority
                DatePc
            }
            }`,
        }

        this.http.post(environment.ApiGraphQl, DataQuery).subscribe((response: any) => {
          resolve(response.data.ChatByPhoneApp || [])

        }, (error) => {
          reject(error);
        });

      } catch (error) {
        reject(error)
      }
    })
  }

  SaveDataCLient(DataPost: any) {
    return new Promise(async (resolve: any, reject: any) => {
      try {
        const headers: any = {
          'method': this._EncryptService.EncryptSSL("RegsterClienteApp"),
          'token': this._EncryptService.EncryptSSL(environment.TokenSSL),
          'platform': this._EncryptService.EncryptSSL("FibexPLay")
        }
        this.http.post(environment.URLApiSSL + `Envio`, DataPost, { headers }).subscribe((response: any) => {

          resolve(response)

        }, (error) => {
          reject(error);
        });

      } catch (error) {
        reject(error)
      }
    })
  }

  GetHistorialOrdenes(IdContrato: any) {
    return new Promise(async (resolve: any, reject: any) => {
      try {
        const headers: any = {
          'method': this._EncryptService.EncryptSSL("HistorialOrdenes"),
          'token': this._EncryptService.EncryptSSL(environment.TokenSSL),
          'platform': this._EncryptService.EncryptSSL("FibexPLay"),
          'idCo': this._EncryptService.EncryptSSL(IdContrato),
          'id': this._EncryptService.EncryptSSL(environment.Lic)
        }
        this.http.get(environment.URLApiSSL + `SAE`, { headers }).subscribe((data: any) => {
          let Historial = JSON.parse(data)
          resolve(Historial.data.info)
        })

      } catch (error) {
        reject(error)
      }
    })
  }

  CorreoBienvenida(Email: any) {
    return new Promise(async (resolve: any, reject: any) => {
      try {

        const DataQuery = {
          query: `
          query{
            SendEmail(
              Correo:"${Email}"
              Titulo:"Bienvenido a la App de FIBEX TELECOM"
            ){
          to
            }
          }`,
        }

        this.http.post(environment.ApiGraphQl, DataQuery).subscribe((response: any) => {
          resolve(response.data.SendEmail)
        }, (error) => {
          reject(error);
        });

      } catch (error) {
        reject(error)
      }
    })
  }

  sinCero(telefono: string) {
    return telefono.slice(0, 1) === "0" ? telefono.slice(1) : telefono;
  }

  sendMail(Email: string, titulo: string, contenido: string, telefono: string = "") {
    return new Promise(async (resolve: any, reject: any) => {
      try {
        const DataQuery = {
          query: `
          query{
            SendEmail(
              Correo: "${Email}"
              Titulo: "${titulo}"
              Contenido: "${contenido}"
              Phone: "58${this.sinCero(telefono)}@c.us"
            ){
          to
            }
          }`,
        }

        this.http.post(environment.ApiGraphQl, DataQuery).subscribe((response: any) => {
          resolve(response.data.SendEmail)
        }, (error) => {
          reject(error);
        });

      } catch (error) {
        reject(error)
      }
    })
  }

  ClienteCRM(Cedula: any) {
    return new Promise(async (resolve: any, reject: any) => {

      const headers = {
        token: this._EncryptService.encryptII(environment.TokenSSL),
        platform: this._EncryptService.encryptII(environment.platform),
        method: this._EncryptService.encryptII(environment.loginIdSslMethod),
        id: this._EncryptService.encryptII(Cedula)
      }

      this.http.get(`${environment.URLApiSSL}SAE`, { headers }).subscribe((data: any) => {
        resolve(data)
      }, (error: any) => {
        reject(error)
      })
    })
  }

  SendComandContr(Data: any) {
    return new Promise(async (resolve: any, reject: any) => {
      try {

        const DataQuery = {
          query: `
          mutation {
            ControlRemoto(Data:{
              Codigo:"${Data.Codigo}"
              Cedula:"${Data.Cedula}"
              Url:"${Data.Url}"
              Comando: "${Data.Comando}"
              Timer: "${Data.Timer}"
            })
          }`,
        }

        this.http.post(environment.ApiGraphQlfp, DataQuery).subscribe((response: any) => {
          resolve(response.data.ControlRemoto)
        }, (error) => {
          reject(error);
        });

      } catch (error) {
        reject(error)
      }
    })
  }

  SendWelcome(Data: any) {
    return new Promise(async (resolve: any, reject: any) => {
      try {
        const headers: any = {
          'method': this._EncryptService.EncryptSSL("WelcomeFibexPlay"),
          'token': this._EncryptService.EncryptSSL(environment.TokenSSL),
          'platform': this._EncryptService.EncryptSSL("FibexPLay")
        }
        this.http.post(`${environment.URLApiSSL}Envio`, Data, { headers }).subscribe((response: any) => {
          resolve(response)
        }, (error) => {
          console.error(error)
          reject(error);
        });

      } catch (error) {
        console.error(error)
        reject(error)
      }
    })
  }

  GetMaxDevice(cedula: any) {
    return new Promise(async (resolve: any, reject: any) => {
      try {
        const headers: any = {
          'method': this._EncryptService.EncryptSSL("GetMaxDeviceFibexPlay"),
          'token': this._EncryptService.EncryptSSL(environment.TokenSSL),
          'platform': this._EncryptService.EncryptSSL("FibexPLay"),
          'cedula': this._EncryptService.EncryptSSL(cedula),
          'lic': this._EncryptService.EncryptSSL(environment.Lic)
        }
        this.http.get(`${environment.URLApiSSL}FibexPlay`, { headers }).subscribe((response: any) => {
          resolve(response)
        }, (error) => {
          console.error(error)
          reject(error);
        });

      } catch (error) {
        console.error(error)
      }
    })
  }

  ConsultaChat_Cola(Phone: any) {
    return new Promise(async (resolve: any, reject: any) => {
      try {

        const DataQuery = {
          query: `
          query{
            ChatCola(
              token:"${environment.Token}"
              Data:{
                phone:"${Phone}"
                lic:"${environment.LicChat}"
                status:0
              }
              Limit:1
            ){
              idEmpresa
              phone
              idConversacion
              idmessage
              timestamp
              contentType    
              content
              TimeRead
              operador
              lic
            }
          }`,
        }

        this.http.post(environment.ApiGraphQl, DataQuery).subscribe((response: any) => {
          resolve(response.data.ChatCola || [])

        }, (error) => {
          reject(error);
        });

      } catch (error) {
        reject(error)
      }
    })
  }

  InsertViewControlCobranza(Data: any) {
    return new Promise(async (resolve: any, reject: any) => {
      try {
        const headers: any = {
          'method': this._EncryptService.EncryptSSL("RegisterControlCb"),
          'token': this._EncryptService.EncryptSSL(environment.TokenSSL),
          'platform': this._EncryptService.EncryptSSL("FibexPLay")
        }
        this.http.post(`${environment.URLApiSSL}Envio`, Data, { headers }).subscribe((ResInsert: any) => {
          resolve(ResInsert)
        }, (error: any) => {
          reject(error)
        })

      } catch (error) {
        reject(error)
      }
    })
  }

  GetPinEmergencia(MesActual: any) {
    return new Promise(async (resolve: any, reject: any) => {
      try {
        const headers: any = {
          'method': this._EncryptService.EncryptSSL("PinEmergencia"),
          'token': this._EncryptService.EncryptSSL(environment.TokenSSL),
          'platform': this._EncryptService.EncryptSSL("FibexPLay"),
          'mes': this._EncryptService.EncryptSSL(MesActual),
          'lic': this._EncryptService.EncryptSSL(environment.Lic)
        }
        this.http.get(`${environment.URLApiSSL}Consultas`, { headers }).subscribe((Res: any) => {
          resolve(Res);
        }, error => {
          reject(error)
        })
      } catch (error) {
        reject(error)
      }
    })
  }

  IActive(Cedula: any, Device: any) {
    return new Promise(async (resolve: any, reject: any) => {
      try {
        const headers: any = {
          'method': this._EncryptService.EncryptSSL("DeviceFPActive"),
          'token': this._EncryptService.EncryptSSL(environment.TokenSSL),
          'platform': this._EncryptService.EncryptSSL("FibexPLay"),
          'cedula': this._EncryptService.EncryptSSL(Cedula),
          'device': this._EncryptService.EncryptSSL(Device)
        }
        this.http.get(`${environment.URLApiSSL}FibexPlay`, { headers }).subscribe((Res: any) => {
          resolve(Res)
        }, (err: any) => {
          reject(err)
        })

      } catch (error) {
        reject(error)
      }
    })
  }

  SendWhatsApp(Text: any, Phone: any) {
    return new Promise(async (resolve: any, reject: any) => {
      try {

        const DataWa = {
          "lic": environment.Lic,
          "Mensaje": Text,
          "Phone": this.FormatPhone(Phone),
          "Archivos": [
            {
              "filename": "",
              "path": ""
            }
          ]
        }
        const headers: any = {
          'method': this._EncryptService.EncryptSSL("SendWhats"),
          'token': this._EncryptService.EncryptSSL(environment.TokenSSL),
          'platform': this._EncryptService.EncryptSSL("FibexPLay")
        }
        this.http.post(environment.URLApiSSL + `Envio`, DataWa, { headers }).subscribe((response: any) => {

          resolve({
            status: response,
            Phone: this.FormatPhone(Phone)
          })

        }, (error) => {
          reject(error);
        });

      } catch (error) {
        reject(error)
      }
    })
  }

  FormatPhone(Phone: any) {
    const initPhone = Phone.substring(0, 3)
    if (Phone.charAt(0) === '0' && initPhone != '000') {
      const newphone = `58${Phone.substring(1, Phone.length)}@c.us`
      return newphone
    } else {

      if (initPhone === '414' || initPhone === '424' || initPhone === '412' || initPhone === '416' || initPhone === '426') {
        return `58${Phone}@c.us`
      } else if (initPhone != '000') {
        return `${Phone}@c.us`
      }
    }
  }

  SendSMS(Phone: any, Text: any) {
    return new Promise(async (resolve: any, reject: any) => {
      try {

        const Data = {
          "to": Phone,
          "Message": Text
        }
        const headers: any = {
          'method': this._EncryptService.EncryptSSL("SendSms"),
          'token': this._EncryptService.EncryptSSL(environment.TokenSSL),
          'platform': this._EncryptService.EncryptSSL("FibexPLay")
        }
        this.http.post(`${environment.URLApiSSL}Envio`, Data, { headers }).subscribe((ResSMS: any) => {
          resolve(ResSMS)
        }, (error: any) => {
          reject(error)
        })

      } catch (error) {
        reject(error)
      }
    })
  }

  FormatPhoneSMS(Phone: any) {
    const initPhone = Phone.substring(0, 3)
    if (Phone.charAt(0) === '0' && initPhone != '000') {
      return Phone
    } else if (initPhone === '414' || initPhone === '424' || initPhone === '412' || initPhone === '416' || initPhone === '426') {
      return `0${Phone}`
    }
  }

  GeneratePin(Cedula: any, tipo: any) {
    return new Promise(async (resolve: any, reject: any) => {
      try {

        // Encabezados de la petición
        const headers = {
          method: this._EncryptService.EncryptSSL(`GenerarPin`),
          token: this._EncryptService.EncryptSSL(environment.TokenSSL),
          platform: this._EncryptService.EncryptSSL("FibexPlay"),
          id: this._EncryptService.EncryptSSL(Cedula),
          tipo: this._EncryptService.EncryptSSL(tipo)
        };

        // Núcleo de la peticion
        this.http.get(environment.URLApiSSL + "Consultas", { headers }).subscribe(Res => {
          resolve(Res)
        }, (error) => {
          reject(error)
        })

      } catch (error) {
        reject(error)
      }
    })
  }

  VerificarPin(Cedula: any, Pin: any) {
    return new Promise(async (resolve: any, reject: any) => {
      try {
        // Encabezados de la petición
        const headers = {
          method: this._EncryptService.EncryptSSL(`VerificarPin`),
          token: this._EncryptService.EncryptSSL(environment.TokenSSL),
          platform: this._EncryptService.EncryptSSL("FibexPlay"),
          id: this._EncryptService.EncryptSSL(Cedula),
          pin: this._EncryptService.EncryptSSL(Pin)
        };
        // Núcleo de la peticion
        this.http.get(environment.URLApiSSL + "Consultas", { headers }).subscribe(Res => {
          resolve(Res)
        }, (error) => {
          reject(error)
        })

      } catch (error) {
        reject(error)
      }
    })
  }

  SendMsgwhatsapp(Datos: any) {
    return new Promise(async (resolve: any, reject: any) => {
      try {
        // Encabezados de la petición
        const headers = {
          method: this._EncryptService.EncryptSSL("IntermediaryW"),
          token: this._EncryptService.EncryptSSL(environment.TokenSSL),
          platform: this._EncryptService.EncryptSSL("FibexPlay"),
        };
        // peticion legal de los datos y encabezados encriptados
        this.http.post(environment.URLApiSSL + "Envio", Datos, { headers }).subscribe((response: any) => {
          resolve(response)
        }, (error) => {
          // this._logs.insertarError(error.message);
          console.error(error)
          reject(error);
        });

      } catch (error) {
        reject(error)
      }
    })
  }
}