import { Component, ElementRef, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { ICommandVideoCallback } from '../video-player/video-player.component';
// import RxPlayer from "rx-player"; // Descartado (No reproduce)
// import Plyr from "plyr";  // No funciona para esta version de angular
// import chimme from "chimee"; // No posee suficiente portabilidad
import Hls from "hls.js";
// import Player from '@oplayer/core'
// import ui from '@oplayer/ui'
import mediaelement from "mediaelement";

console.log("mediaelement", mediaelement);


@Component({
  selector: 'app-video-player-hls',
  templateUrl: './video-player-hls.component.html',
  styleUrls: ['./video-player-hls.component.scss'],
})
export class VideoPlayerHlsComponent implements OnInit {
  @ViewChild("video_hls", {static: true}) video_hls: ElementRef; 
  show: boolean = true;
  @Input() volumen: number; // rango del 0 al 100
  @Input() url: string | null; // url del video
  @Input() blackborders: boolean; // eliminar border negros
  @Input() playning: boolean; // reproduciendo
  @Input() muted: boolean; // Muteado
  @Input() showPicture: boolean;
  @Output() onVolumen: EventEmitter<number> = new EventEmitter();
  @Output() callback: EventEmitter<ICommandVideoCallback> = new EventEmitter();
  @Output() currentTime: EventEmitter<{current: number, max: number}> = new EventEmitter();
  @Output() loading: EventEmitter<boolean> = new EventEmitter();
  @Output() error: EventEmitter<void> = new EventEmitter();
  @Output() closePicture: EventEmitter<void> = new EventEmitter();

  private player: Hls | null;
  private video: HTMLVideoElement | null;
  private lastUrl: string | null;
  public loadingPlayer: boolean = true;

  constructor() { }

  ngOnInit() {
    function callback(this: VideoPlayerHlsComponent, type: "getTimeLine"): number;
    function callback(this: VideoPlayerHlsComponent, type: "getMaxTimeLine"): number;
    function callback(this: VideoPlayerHlsComponent, type: "setTimeLine", v: number): void;
    function callback(this: VideoPlayerHlsComponent, type: "getTimeLine" | "getMaxTimeLine" | "setTimeLine", v?: number): number | void {
      switch(type) {
        case "getMaxTimeLine":
          return this.video ? this.video.duration : 0;

        case "getTimeLine":
          return this.video ? this.video.currentTime : 0;

        case "setTimeLine":
          this.video.currentTime = v || 0;
      }

      this.InitVideoJS();
    }
    
    this.InitVideoJS();
    this.callback.emit(callback.bind(this));
  }

  ngOnChanges() {
    this.InitVideoJS();
  }

  ngOnDestroy() {
    this.Destroy();
  }

  async InitVideoJS() {

    if(this.show) {
      if(!this.player) {
        this.video = this.video_hls.nativeElement;
        this.video.muted = this.muted;
        this.video.volume = this.volumen / 100;
        this.video.style.width = this.video.style.height = "100%";

        this.video.addEventListener("timeupdate", (ev) => {
          this.currentTime.emit({current: this.video.currentTime, max: this.video.duration});
        })
        this.video.addEventListener("waiting", (ev) => {
          this.loading.emit(true);
          this.loadingPlayer = true;
        })
        this.video.addEventListener("canplaythrough", (ev) => {
          this.loading.emit(false);
          this.loadingPlayer = false;
        })
        this.video.addEventListener("error", (ev: any) => {
          console.log(">>>>>>> ERROR CODE:", ev.target.error.code);
          if(ev.target.error.code === MediaError.MEDIA_ERR_SRC_NOT_SUPPORTED) {
            this.error.emit();
          }
        })
        this.video.addEventListener("volumechange", (ev) => {
          this.onVolumen.emit(this.video.volume*100);
        })
        this.video.addEventListener("leavepictureinpicture", (ev) => {
          this.closePicture.emit();
        })

        console.log("HERE URL BANANA:", this.url);
        this.player = new Hls({
          testBandwidth: true,
        });
        // this.player.bandwidthEstimate = 30000000;
        this.player.attachMedia(this.video);
        this.player.loadSource(this.url);
        // this.player.loadVideo({
        //   url: this.url,
        //   transport: "directfile",
        //   autoPlay: this.playning
        // })
        
        // this.player = videojs(this.video, {
        //   sources: [
        //     {
        //       src: this.url || ""
        //     }
        //   ], 
        //   controls: false, 
        //   autoplay: this.playning, 
        //   preload: "metadata",
        //   with: "100%",
        //   height: "100%"
        // });
      }
      else {
        if((this.video.volume*100) !== this.volumen) {
          this.video.volume = (this.volumen / 100);
          if((this.video.volume*100) !== this.volumen) {
            this.onVolumen.emit((this.video.volume*100))
          }
        }
        if(this.lastUrl !== this.url) {
          this.player.loadSource(this.url);
          setTimeout(() => {
            console.log("LEVELS::::", this.player.levels);
          }, 5000);
        };
        if(this.muted !== this.video.muted) this.video.muted = this.muted;
        
        if(!this.video.paused !== this.playning) {
          if(this.playning) this.video.play();
          else this.video.pause();
        }
      }
      this.lastUrl = this.url;
      
      if(this.showPicture) {
        if((document as any).pictureInPictureElement !== this.video) {
          // if((document as any).pictureInPictureElement) await (document as any).exitPictureInPicture();
          if(this.showPicture) {
              await (this.video as any).requestPictureInPicture();
          }
        }
      }
      else {
        if((document as any).pictureInPictureElement === this.video) {
          await (document as any).exitPictureInPicture();
        }
      }
      
    }
    else {
      if(this.player) this.Destroy();
    }
  }

  Destroy() {
    if(this.player) {
      this.player.destroy();
      this.player = null;
    }
  }

  
}
