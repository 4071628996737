import { Component, ElementRef, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import Player from 'video.js/dist/types/player';
import { ICommandVideoCallback } from '../video-player/video-player.component';
// import videojs from 'video.js';


// Se importa desde index.html
declare var videojs: any;

@Component({
  selector: 'app-video-player-videojs',
  templateUrl: './video-player-videojs.component.html',
  styleUrls: ['./video-player-videojs.component.scss'],
})
export class VideoPlayerVideoJSComponent implements OnInit {
  @ViewChild("video_hls", {static: true}) video_hls: ElementRef; 
  show: boolean = true;
  @Input() volumen: number; // rango del 0 al 100
  @Input() url: string | null; // url del video
  @Input() blackborders: boolean; // eliminar border negros
  @Input() playning: boolean; // reproduciendo
  @Input() muted: boolean; // Muteado
  @Input() showPicture: boolean;
  @Output() onVolumen: EventEmitter<number> = new EventEmitter();
  @Output() callback: EventEmitter<ICommandVideoCallback> = new EventEmitter();
  @Output() currentTime: EventEmitter<{current: number, max: number}> = new EventEmitter();
  @Output() loading: EventEmitter<boolean> = new EventEmitter();
  @Output() error: EventEmitter<void> = new EventEmitter();
  @Output() closePicture: EventEmitter<void> = new EventEmitter();

  private player: Player | null;
  private video: HTMLVideoElement | null;
  private lastUrl: string | null;
  public loadingPlayer: boolean = true;

  constructor() { }

  ngOnInit() {
    function callback(this: VideoPlayerVideoJSComponent, type: "getTimeLine"): number;
    function callback(this: VideoPlayerVideoJSComponent, type: "getMaxTimeLine"): number;
    function callback(this: VideoPlayerVideoJSComponent, type: "setTimeLine", v: number): void;
    function callback(this: VideoPlayerVideoJSComponent, type: "getTimeLine" | "getMaxTimeLine" | "setTimeLine", v?: number): number | void {
      switch(type) {
        case "getMaxTimeLine":
          return this.video ? this.video.duration : 0;

        case "getTimeLine":
          return this.video ? this.video.currentTime : 0;

        case "setTimeLine":
          this.video.currentTime = v || 0;
      }

      this.InitVideoJS();
    }
    
    this.InitVideoJS();
    this.callback.emit(callback.bind(this));
  }

  ngOnChanges() {
    this.InitVideoJS();
  }

  ngOnDestroy() {
    this.Destroy();
  }

  async InitVideoJS() {

    if(this.show) {
      if(!this.player) {
        this.video = this.video_hls.nativeElement;
        this.video.muted = this.muted;
        this.video.volume = this.volumen / 100;
        this.video.style.width = this.video.style.height = "100%";

        this.video.addEventListener("timeupdate", (ev) => {
          this.currentTime.emit({current: this.video.currentTime, max: this.video.duration});
        })
        this.video.addEventListener("waiting", (ev) => {
          this.loadingPlayer = true;
          this.loading.emit(true);
        })
        this.video.addEventListener("canplaythrough", (ev) => {
          this.loadingPlayer = false;
          this.loading.emit(false);
        })
        this.video.addEventListener("error", (ev: any) => {
          console.log(">>>>>>> ERROR CODE:", ev.target.error.code);
          if(ev.target.error.code === MediaError.MEDIA_ERR_SRC_NOT_SUPPORTED) {
            this.error.emit();
          }
          else {
            // Force load
            console.log("Force load");
            console.error(ev);
            this.player.load();
          }
        })
        this.video.addEventListener("volumechange", (ev) => {
          this.onVolumen.emit(this.video.volume*100);
        })
        this.video.addEventListener("leavepictureinpicture", (ev) => {
          this.closePicture.emit();
        })
        
        this.player = videojs(this.video, {
          sources: [
            {
              src: this.url || "",
              type: "application/x-mpegURL"
            }
          ], 
          controls: false, 
          autoplay: this.playning, 
          preload: "metadata",
          with: "100%",
          height: "100%",
          
        });
      }
      else {
        if((this.player.volume()*100) !== this.volumen) {
          this.player.volume(this.volumen / 100);
          if((this.player.volume()*100) !== this.volumen) {
            this.onVolumen.emit((this.player.volume()*100))
          }
        }
        if(this.lastUrl !== this.url) this.player.src({src:this.url, type:"application/x-mpegURL"});
        if(this.muted !== this.video.muted) this.video.muted = this.muted;
        if(this.player.paused() !== !this.playning) {
          if(this.playning) this.player.play();
          else this.player.pause();
        }
      }
      this.lastUrl = this.url;
      
      if(this.showPicture) {
        if((document as any).pictureInPictureElement !== this.video) {
          // if((document as any).pictureInPictureElement) await (document as any).exitPictureInPicture();
          if(this.showPicture) {
              await (this.video as any).requestPictureInPicture();
          }
        }
      }
      else {
        if((document as any).pictureInPictureElement === this.video) {
          await (document as any).exitPictureInPicture();
        }
      }
      
    }
    else {
      if(this.player) this.Destroy();
    }
  }

  Destroy() {
    if(this.player) {
      this.player.dispose();
      this.player = null;
    }
  }

  
}
