import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef, Input } from '@angular/core';

@Component({
  selector: 'app-select-player-view',
  templateUrl: './select-player-view.component.html',
  styleUrls: ['./select-player-view.component.scss'],
})
export class SelectPlayerViewComponent implements OnInit {
  @ViewChild("container", {static: true}) container: ElementRef;
  // @Output() exit: EventEmitter<void> = new EventEmitter();
  @Input() defaultValue: number = 0;
  @Output() select: EventEmitter<number> = new EventEmitter();
  
  show: number | null = null;
  views = [
    {
      name: "Simple pantalla",
      img: "../../../assets/player/view1.png",
      type: 0,
    },
    {
      name: "2 Pantallas",
      img: "../../../assets/player/view2.png",
      type: 1,
    },
    {
      name: "3 Pantallas",
      img: "../../../assets/player/view3.png",
      type: 2,
    },
    {
      name: "3 Pantallas (invertido)",
      img: "../../../assets/player/view4.png",
      type: 3,
    },
    {
      name: "4 Pantallas",
      img: "../../../assets/player/view5.png",
      type: 4,
    },
    {
      name: "3 Pantallas (lateral)",
      img: "/assets/player/view6.png",
      type: 5,
    }
  ]

  constructor() { }

  ngOnInit() {
    setTimeout(() => {
      console.log((this.container.nativeElement as HTMLElement).getElementsByTagName("button")[this.defaultValue].focus())
    }, 50);
  }

}
