import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ConsultaVideoService {
  public video = null;
  constructor() { }
  setVideo(video: any) {
    this.video = video;
  }
} 