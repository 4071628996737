import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { IonicModule, IonicRouteStrategy, PopoverController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AgmCoreModule } from '@agm/core';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { IonicStorageModule } from '@ionic/storage';
import { ServiceWorkerModule } from '@angular/service-worker';

import { environment } from '../environments/environment';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { TranslateProvider } from './providers';

// Modal Pages
import { VideoPlayerPageModule } from './pages/modal/video-player/video-player.module';
import { GrillaModule } from './shared/grilla/grilla.module';
import { UpdatePasswordPageModule } from './pages/modal/update-password/update-password.module';
import { FavoritesPageModule } from './pages/favorites/favorites.module';
import { NotificationModalPageModule } from './pages/modal/notification-modal/notification-modal.module';
import { PinRegistrationPageModule } from './pages/modal/pin-registration/pin-registration.module';
import { PinValidationPageModule } from './pages/modal/pin-validation/pin-validation.module';
import { UniqueDeviceID }  from '@ionic-native/unique-device-id/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { PopoverComponent } from './pages/modal/popover/popover.component';
import { ImageDirective } from './Directive/appImagen.directive';

import { QRCodeModule } from 'angularx-qrcode';
import { RemoteControllerComponent } from './pages/modal/remote-controller/remote-controller.component';
import { ForgotpassModule } from './pages/modal/forgot-password-page/forgotpass.module';
import { ChannelsModule } from './pages/modal/channels/channels.module';
import { ComponentsModule } from './components/components.module';
// import { SldpComponent } from './components/sldp/sldp.component';


export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent,PopoverComponent,RemoteControllerComponent, ImageDirective],
  imports: [
    ComponentsModule,
    QRCodeModule,
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot(environment.config),
    AppRoutingModule,
    HttpClientModule,
    VideoPlayerPageModule,
    GrillaModule,
    NotificationModalPageModule,
    PinRegistrationPageModule,
    PinValidationPageModule,
    ForgotpassModule,
    ChannelsModule,
    FavoritesPageModule,
    UpdatePasswordPageModule,
    IonicStorageModule.forRoot({
      name: '__ionbooking2',
      driverOrder: ['indexeddb', 'sqlite', 'websql']
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyB3zfryBJhGSyX955qUwNn2Rfnr5lpeVlo'
    }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    StatusBar,
    SplashScreen,
    Network,
    Geolocation,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    UniqueDeviceID,
    TranslateProvider
  ],
  entryComponents:[PopoverComponent,RemoteControllerComponent,],
  bootstrap: [AppComponent]
})
export class AppModule {}
