import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { EncryptService } from './encrypt.service';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {
  private RedFibex = new Subject<boolean>();
  private CDNList = new Subject<boolean>();
  constructor(private encryptService: EncryptService) {this.RedFibex.next(true); this.CDNList.next(false); }

  public async set(item: string, data: any) {
    try{
    localStorage.setItem(item, this.encryptService.encrypt(JSON.stringify(data)));
    // localStorage.setItem(item,(JSON.stringify(data)));
    }catch(e){      
      this.removeAll()
      window.location.reload()
    }
  }

  //consologea cada item del localStorage
  iterar() {
    var i
    for (i = 0; i < localStorage.length; i++)   {
        console.log(localStorage.key(i) + "=[" + this.encryptService.decrypt(localStorage.getItem(localStorage.key(i))) + "]");
    }
  }
  setRedFibex(RedFibex: boolean) {
    this.RedFibex.next(RedFibex);
  }

  getRedFibex(): Observable<boolean> {
    return this.RedFibex.asObservable();
  }
  setCDNList(CDNList: boolean) {
    this.CDNList.next(CDNList);
  }

  getCDNList(): Observable<boolean> {
    return this.CDNList.asObservable();
  }

  public get(item: string) {
    try{
      return JSON.parse(this.encryptService.decrypt(localStorage.getItem(item)));
      // return JSON.parse((localStorage.getItem(item)));
    }catch(err){
      this.removeAll()
      window.location.reload()
    }
  }

  public remove(item: string) {
    localStorage.removeItem(item);
  }

  public removeAll() {
    localStorage.clear();
  }
}