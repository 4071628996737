import { Injectable, EventEmitter } from '@angular/core';
import { UserInfo } from '../interfaces/userInfo';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../environments/environment';
import { LocalStorageService } from './local-storage.service';
import { EncryptService } from './encrypt.service';

@Injectable({
  providedIn: 'root'
})

export class UserAuthenticationService {
  public userState: EventEmitter<any> = new EventEmitter<any>()
  private idUniqueSystem: string = ""
  constructor(
    public http: HttpClient,
    private _localStorageService: LocalStorageService,
    private _EncryptService: EncryptService
  ) { }

  public getUser() {
    return this._localStorageService.get('user') || null;
  }

  public registerUser(userInfo: UserInfo) {
    this.idUniqueSystem = this._localStorageService.get('idUniqueSystem');
    this._localStorageService.removeAll();
    this._localStorageService.set('idUniqueSystem', this.idUniqueSystem);

    this._createAccount(userInfo);
    this.login({ email: userInfo.email, password: userInfo.password })
    this.userState.emit({ ...userInfo, municipio: "", estado: "", parroquia: "" });
    return true;
  }

  private _createAccount(userInfo: UserInfo) {
    let infoUser = { ...userInfo, municipio: "", estado: "", parroquia: "" }
    this._localStorageService.set('user', infoUser);
  }

  public noRegisterdUser() {
    const users = this._localStorageService.get('user');
    if (!users) {
      return true;
    }
    return false;
  }

  public login(userInfo: { email: string, password: string }) {
    try {
      const registeredUser = this._localStorageService.get('user') || null;
      if (!registeredUser) {
        return false;
      }
      const validUser = userInfo.email.toUpperCase() === registeredUser.email.toUpperCase() && userInfo.password === registeredUser.password;
      if (!validUser) {
        return false;
      }

      this._localStorageService.set('auth-user', userInfo);
      return true;
    } catch (err) { }
  }
  

  // public loginDNI(userInfo: { cedula: string }) {
  //   try {
  //     const registeredUser = this._localStorageService.get('user') || null;
  //     // console.log(registeredUser) //Clean console
  //     if (!registeredUser) {
  //       return false;
  //     }
  //     const validUser =  userInfo.cedula === registeredUser.cedula;
  //     if (!validUser) {
  //       return false;
  //     }

  //     this._localStorageService.set('auth-user', userInfo);
  //     return true;
  //   } catch (err) { }
  // }

  modifyUser(userInfo: any) {
    const user = this._localStorageService.get('user');
    let userInfoUpdate = { ...user, ...userInfo };
    this._localStorageService.set('user', userInfoUpdate);
    this.userState.emit(userInfoUpdate);
  }

  public logout() {
    this._localStorageService.remove('auth-user');
    // this._localStorageService.remove('user');
    this._localStorageService.setRedFibex(false);
    this._localStorageService.setCDNList(false);
    
    if((document as any).pictureInPictureElement) {
      (document as any).exitPictureInPicture();
    }
    // localStorage.clear();
  }

  public async getDatasByDNI(dni: string) {
    return new Promise(async (resolve: any, reject: any) => {
      try {
        const headers: any = {
          'method': this._EncryptService.EncryptSSL("AllInfoClientByDNI"),
          'token': this._EncryptService.EncryptSSL(environment.TokenSSL),
          'platform': this._EncryptService.EncryptSSL("FibexPLay"),
          'id': this._EncryptService.EncryptSSL(dni),
          'lic': this._EncryptService.EncryptSSL(environment.Lic)
        }
        await this.http.get(environment.URLApiSSL + `SAE`, { headers }).subscribe((Datas: any) => {
          if (Datas) {
            resolve(Datas)
          } else {
            resolve({ status: false})
          }
        })
      }catch (error) {
        console.error(error);
        reject(error);
      }
    })
  }


  // public async getDatasByDNI(dni: string) {
  //   return new Promise(async (resolve: any, reject: any) => {
  //     try {
  //       // fetch(`https://login.thomas-talk.me/api/ci/?cedula=${dni}`)
  //       // .then(response => response.json())
  //       // .then(data => console.log(data))
  //       this.http.get(`https://login.thomas-talk.me/api/ci/?cedula=${dni}`).subscribe((Datas: any) => {
  //         if (Datas) {
  //           resolve(Datas)
  //         } else {
  //           console.log({ status: false})
  //           resolve({ status: false})
  //         }
  //       })
  //     }catch (error) {
  //       console.error(error);
  //       reject(error);
  //     }
  //   })
  // }

  public async loginCRM(email: string, password?: string) {
    //http://localhost/phpass.php?email=HADADJORGE1@GMAIL.COM&pass=jermsof
    return new Promise(async (resolve: any, reject: any) => {
      try {

        this.http.get(`${environment.apiPhP}email=${email}&pass=${password}`).subscribe((ResCrm: any) => {
          if (ResCrm) {
            resolve(ResCrm)
          } else {
            resolve({ status: false })
          }
        }, (err: any) => {

          if (err.error && err.error.text) {
            const DataJson = err.error.text
            let DataJSONFinal

            if (DataJson.includes('}{')) {
              DataJSONFinal = `[${DataJson.replace('}{', '},{')}]`
              const DataCRM = JSON.parse(DataJSONFinal)
              const d = DataCRM.find((DCr: any) => DCr.status === true)
              resolve(d ? d : { status: false })
            } else {
              const DataCRM = JSON.parse(DataJson)
              resolve(DataCRM)
            }

          } else {
            reject(err)
          }

        })

      } catch (error) {
        console.error(error)
        reject(error)
      }
    })
    /* try {
      const responseCliente: any = await this.http.get(`${environment.apiPhP}email=${email}&pass=${password}`).toPromise();
      if (!responseCliente) {
        return { status: false };
      }
      return responseCliente;
    } catch (error: any) {
      console.error(error.error)
      return { status: false };
    } */
  }

  public async resetPassword(email: string, password: string) {
    try {
      const response: any = await this.http.get(`${environment.apiResetPassword}email=${email}&pass=${password}`).toPromise();
      return response;
    } catch (error) {
      return {
        status: false,
        email: null,
        password: null,
      }
    }
  }

  public async verifyCedula(cedula: string) {
    try {
      const response: any = await this.http.get(`${environment.apiVerifyCedula}cedula=${cedula}`).toPromise();
      return response;
    } catch (error) {
      return {
        status: false,
        cedula: null,
      };
    }
  }

  public async querySecurityQuestions(email: string, opTipe: number = 2, preguntaUno: string = "", respuestaUno: string = "", preguntaDos: string = "", respuestaDos: string = "") {
    //http://localhost/phpass.php?email=HADADJORGE1@GMAIL.COM&pass=jermsof
    try {
      const response: any = await this.http.get(`${environment.apiSecurityQuestions}email=${email}&opTipe=${opTipe}&preguntaUno=${preguntaUno}&respuestaUno=${respuestaUno}&preguntaDos=${preguntaDos}&respuestaDos=${respuestaDos}`).toPromise();
      if (!response.status) {
        return {
          status: false,
          message: response.message,
          data: null,
        };
      }
      return response;

    } catch (error) {
      return {
        status: false,
        message: "Las preguntas de seguridad no pudieron ser consultadas.",
        data: null,
      };
    }

  }

}