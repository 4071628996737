import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();

  if (window) {
    window.console.log = function () { };
    console.log = function () { };
    console.error = function () { };
    console.warn = function () { };
  }
}

//CANCELA TODOS LOS MENSAJES DE LA CONSOLA



platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.log(err));
