

enum DIRECTION {
    LEFT, TOP, RIGHT, BOTTOM
}

export default class Controls {
    private coorX: number = 0;
    private coorY: number = 0;
    
    constructor() {

    }

    private GetElementsFocusable() {
        const elements: Element[] = [];
        const elementFocused = this.GetElementFocused();

        console.log("OLD FOCUSED", elementFocused);

        document.querySelectorAll('button:not([disabled]), [href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), [tabindex]:not([tabindex="-1"]):not([disabled]), details:not([disabled]), summary:not(:disabled)')
            .forEach(element => {
                elements.push(element)
            })

        function RecorrerShadowDOM(element: ChildNode) {
            const node = element;

            if((element as any).shadowRoot) {
                if(!element.isSameNode(elementFocused)) {
                    (element as any).shadowRoot.querySelectorAll('button:not([disabled]), [href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), [tabindex]:not([tabindex="-1"]):not([disabled]), details:not([disabled]), summary:not(:disabled)')
                        .forEach(element => {
                            elements.push(element)
                        })
                    RecorrerShadowDOM((element as any).shadowRoot);
                    // console.log("SHADOW:", (element as any).shadowRoot);
                }
            }
            
            if(node.childNodes.length) {
                for(let n of node.childNodes as any) {
                    RecorrerShadowDOM(n)
                }
            }
        }

        RecorrerShadowDOM(document.body);

        
        return elements.filter(element => {
            const rect = element.getBoundingClientRect();

            return rect.width > 5 && rect.height > 5 && !element.isSameNode(elementFocused);
        })
    }

    private GetZIndexElement(element: Element) {
        let zIndex;
        do {
            zIndex = String(getComputedStyle(element)["zIndex"]).match(/\d+/);
            if(zIndex) zIndex = zIndex[0];
            else zIndex = "0";
            element = element.parentElement;
        } while(zIndex == "0" && !document.body.isSameNode(element) && element);
        return zIndex;
    }

    private GetElementsByDirection(direction: DIRECTION) {
        const focusedElement = this.GetElementFocused();
        const rectFocus: {left: number, top: number, bottom: number, right: number} = (focusedElement && focusedElement.getBoundingClientRect()) || {
            left: this.coorX,
            top: this.coorY,
            bottom: this.coorY,
            right: this.coorX
        };

        // Filtrar por direccion
        // const filterInpact = this.GetElementsFocusable().filter(element => {
        //     const rectElement = element.getBoundingClientRect();
            
        //     switch(direction) {
        //         case DIRECTION.LEFT:
        //             return rectFocus.right > rectElement.left;

        //         case DIRECTION.TOP:
        //             return rectFocus.bottom > rectElement.top;

        //         case DIRECTION.BOTTOM:
        //             return rectFocus.top < rectElement.bottom;
                
        //         case DIRECTION.RIGHT:
        //             return rectFocus.left < rectElement.right;
        //     }
        // })

        let filterDirection = this.GetElementsFocusable()
            .filter(element => {
                const rectElement = element.getBoundingClientRect();
                
                switch(direction) {
                    case DIRECTION.LEFT:
                        return rectFocus.right > rectElement.left;

                    case DIRECTION.TOP:
                        return rectFocus.bottom > rectElement.top;

                    case DIRECTION.BOTTOM:
                        return rectFocus.top < rectElement.bottom;
                    
                    case DIRECTION.RIGHT:
                        return rectFocus.left < rectElement.right;
                }
            })

        

        

        // const filterSimple = filterDirection.filter(element => {
        //     const rectElement = element.getBoundingClientRect();
            
        //     switch(direction) {
        //         case DIRECTION.LEFT:
        //             return ((rectFocus.right+rectFocus.left)/2) > rectElement.right;

        //         case DIRECTION.TOP:
        //             return ((rectFocus.top+rectFocus.bottom)/2) > rectElement.bottom;

        //         case DIRECTION.BOTTOM:
        //             return ((rectFocus.top+rectFocus.bottom)/2) < rectElement.top;
                
        //         case DIRECTION.RIGHT:
        //             return ((rectFocus.right+rectFocus.left)/2) < rectElement.left;
        //     }
        // })

        const filterProfessional = filterDirection.filter(element => {
            const rectElement = element.getBoundingClientRect();
            
            switch(direction) {
                case DIRECTION.LEFT:
                    return ((rectFocus.right+rectFocus.left)/2) > rectElement.right && rectFocus.left > rectElement.left;

                case DIRECTION.TOP:
                    return ((rectFocus.top+rectFocus.bottom)/2) > rectElement.bottom && rectFocus.top > rectElement.top;

                case DIRECTION.BOTTOM:
                    return ((rectFocus.top+rectFocus.bottom)/2) < rectElement.top && rectFocus.bottom < rectElement.bottom;
                
                case DIRECTION.RIGHT:
                    return ((rectFocus.right+rectFocus.left)/2) < rectElement.left && rectFocus.right < rectElement.right;
            }
        })

        // Filtramos por linea (obtener sólo los mas proximos unicamente a la dirección)
        // const filterStrict = filterDirection.filter(element => {
        //     const rectElement = element.getBoundingClientRect();
            
        //     switch(direction) {
        //         case DIRECTION.LEFT:
        //             return rectFocus.bottom > rectElement.top && rectFocus.top < rectElement.bottom;

        //         case DIRECTION.TOP:
        //             return rectFocus.left < rectElement.right && rectFocus.right > rectElement.left;

        //         case DIRECTION.BOTTOM:
        //             return rectFocus.left < rectElement.right && rectFocus.right > rectElement.left;
                
        //         case DIRECTION.RIGHT:
        //             return rectFocus.bottom > rectElement.top && rectFocus.top < rectElement.bottom;
        //     }
        // })
        const filterStrict = filterDirection.filter(element => {
            const rectElement = element.getBoundingClientRect();
            
            switch(direction) {
                case DIRECTION.LEFT:
                    var centerVertical = (rectElement.top + rectElement.bottom) / 2;
                    return rectFocus.bottom > centerVertical && rectFocus.top < centerVertical;

                case DIRECTION.TOP:
                    var centerVertical = (rectElement.left + rectElement.right) / 2;
                    return rectFocus.left < centerVertical && rectFocus.right > centerVertical;
                    
                    case DIRECTION.BOTTOM:
                    var centerVertical = (rectElement.left + rectElement.right) / 2;
                    return rectFocus.left < centerVertical && rectFocus.right > centerVertical;
                
                case DIRECTION.RIGHT:
                    var centerVertical = (rectElement.top + rectElement.bottom) / 2;
                    return rectFocus.bottom > centerVertical && rectFocus.top < centerVertical;
            }
        })

        let resultFilter = filterStrict.length ? filterStrict : filterProfessional;

        if(focusedElement) {
            const zIndex = this.GetZIndexElement(focusedElement);
            console.log("FOCUSED")
            const superStrictedFilterDirection = resultFilter.filter(element => {
                console.log(`COMPARE(${zIndex}, ${this.GetZIndexElement(element)})`)

                return this.GetZIndexElement(element) == zIndex
            });

            console.log("SUPER FOCUSED", superStrictedFilterDirection.map(element => ({zIndex: this.GetZIndexElement(element), element})))
            if(superStrictedFilterDirection.length) {
                resultFilter = superStrictedFilterDirection;
            }
        }
        return resultFilter;
    }

    private GetNearestElement(x: number, y: number, elements: Element[]): Element | null {
        const distances = elements.map((element, index) => {
            const {x: coorX, y: coorY} = this.GetCoorsByElement(element);

            return {
                distance: Math.sqrt((x-coorX)*(x-coorX) + (y-coorY)*(y-coorY)),
                index: index
            };
        }).sort((a, b) => (a.distance-b.distance));

        return elements[distances[0] && distances[0].index] || null;
    }

    private GetElementFocused(): Element | null {
        return document.querySelector(":focus") || null;
    }

    private GetCoorsByElement(element: Element) {
        const rect = element.getBoundingClientRect();

        return {
            x: (rect.left + rect.right) / 2,
            y: (rect.top + rect.bottom) / 2
        }
    }

    private HandlerMove(direction: DIRECTION) {
        const elementFocused = this.GetElementFocused();
        const rect = elementFocused && elementFocused.getBoundingClientRect();
        const {x, y} = (elementFocused && this.GetCoorsByElement(elementFocused)) || {x: this.coorX, y: this.coorY};

        const elementsByDirection = this.GetElementsByDirection(direction);
        let nearestElement: any;

        switch(direction) {
            case DIRECTION.LEFT:
                nearestElement = this.GetNearestElement((rect && rect.left) || x, y, elementsByDirection);
                break;

            case DIRECTION.BOTTOM:
                nearestElement = this.GetNearestElement(x, (rect && rect.bottom) || y, elementsByDirection);
                break;

            case DIRECTION.TOP:
                nearestElement = this.GetNearestElement(x, (rect && rect.top) || y, elementsByDirection);
                break;

            case DIRECTION.RIGHT:
                nearestElement = this.GetNearestElement((rect && rect.right) || x, y, elementsByDirection);
                break;
        }

        console.log(elementsByDirection);
        console.log(elementFocused)
        
        if(nearestElement) {
            // Set Focus
            const coor = this.GetCoorsByElement(nearestElement);
            this.coorX = coor.x;
            this.coorY = coor.y;
            
            if( typeof nearestElement.focus === "function") {
                nearestElement.focus();
                nearestElement.scrollIntoView({
                    block: "center",
                    behavior: "smooth",
                    inline: "nearest"
                  })
            }
        }
    }

    /**
     * Permite manejar los eventos del teclado
     * @param event Evento del teclado asociado
     */
    private HandlerKeydown = (event: KeyboardEvent) => {
        switch(event.keyCode) {
            case 37: // Flecha izquierda
                event.preventDefault();
                this.HandlerMove(DIRECTION.LEFT);
                break;
                
            case 38: // Flecha arriba
                event.preventDefault();
                this.HandlerMove(DIRECTION.TOP);
                break;
                
            case 39: // Flecha derecha
                event.preventDefault();
                this.HandlerMove(DIRECTION.RIGHT);
                break;
                
            case 40: // Flecha abajo
                event.preventDefault();
                this.HandlerMove(DIRECTION.BOTTOM);
                break;
        }
    }

    /**
     * Registra los controles
     */
    public Register() {
        document.addEventListener("keydown", this.HandlerKeydown);
    }

    /**
     * Desregistra los controles
     */
    public Unregister() {
        document.removeEventListener("keydown", this.HandlerKeydown);
    }
}