import { Component, ElementRef, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
// import Player from 'video.js/dist/types/player';
// import * as videoStreaming from '@videojs/http-streaming';
import { ICommandVideoCallback } from '../video-player/video-player.component';
// import Player from 'video.js/dist/types/player';
// declare var shaka: any;
import mpegts from "mpegts.js";

@Component({
  selector: 'app-video-player-http-streaming',
  templateUrl: './video-player-http-streaming.component.html',
  styleUrls: ['./video-player-http-streaming.component.scss'],
})
export class VideoPlayerHttpStreamingComponent implements OnInit {
  @ViewChild("video_hls", {static: true}) video_hls: ElementRef; 
  show: boolean = true;
  @Input() volumen: number; // rango del 0 al 100
  @Input() url: string | null; // url del video
  @Input() blackborders: boolean; // eliminar border negros
  @Input() playning: boolean; // reproduciendo
  @Input() muted: boolean; // Muteado
  @Input() showPicture: boolean;
  @Output() onVolumen: EventEmitter<number> = new EventEmitter();
  @Output() callback: EventEmitter<ICommandVideoCallback> = new EventEmitter();
  @Output() currentTime: EventEmitter<{current: number, max: number}> = new EventEmitter();
  @Output() loading: EventEmitter<boolean> = new EventEmitter();
  @Output() error: EventEmitter<void> = new EventEmitter();
  @Output() closePicture: EventEmitter<void> = new EventEmitter();

  private player: mpegts.Player | null;
  private video: HTMLVideoElement | null;
  private lastUrl: string | null;

  constructor() { }

  ngOnInit() {
    function callback(this: VideoPlayerHttpStreamingComponent, type: "getTimeLine"): number;
    function callback(this: VideoPlayerHttpStreamingComponent, type: "getMaxTimeLine"): number;
    function callback(this: VideoPlayerHttpStreamingComponent, type: "setTimeLine", v: number): void;
    function callback(this: VideoPlayerHttpStreamingComponent, type: "getTimeLine" | "getMaxTimeLine" | "setTimeLine", v?: number): number | void {
      switch(type) {
        case "getMaxTimeLine":
          return this.video ? this.video.duration : 0;

        case "getTimeLine":
          return this.video ? this.video.currentTime : 0;

        case "setTimeLine":
          this.video.currentTime = v || 0;
      }

      this.InitVideoJS();
    }
    
    this.InitVideoJS();
    this.callback.emit(callback.bind(this));
  }

  ngOnChanges() {
    this.InitVideoJS();
  }

  ngOnDestroy() {
    this.Destroy();
  }

  async InitVideoJS() {

    console.log("HERE PLAYER")

    if(this.show) {
      if(!this.player) {
        this.video = this.video_hls.nativeElement;
        this.video.muted = this.muted;
        this.video.volume = this.volumen / 100;
        this.video.style.width = this.video.style.height = "100%";

        this.video.addEventListener("timeupdate", (ev) => {
          this.currentTime.emit({current: this.video.currentTime, max: this.video.duration});
        })
        this.video.addEventListener("waiting", (ev) => {
          this.loading.emit(true);
        })
        this.video.addEventListener("canplaythrough", (ev) => {
          this.loading.emit(false);
        })
        this.video.addEventListener("error", (ev: any) => {
          console.log(">>>>>>> ERROR CODE:", ev.target.error.code);
          if(ev.target.error.code === MediaError.MEDIA_ERR_SRC_NOT_SUPPORTED) {
            this.error.emit();
          }
        })
        this.video.addEventListener("volumechange", (ev) => {
          this.onVolumen.emit(this.video.volume*100);
        })
        this.video.addEventListener("leavepictureinpicture", (ev) => {
          this.closePicture.emit();
        })


        this.player = mpegts.createPlayer({
          type: "mpegts",
          isLive: true,
          url: this.url
        });
        this.player.attachMediaElement(this.video);
        this.player.load();
        this.player.play();

        // this.player = new clappr.Player({
        //   source: this.url,
        //   parent
        // })
        
        // this.player = new xgplayer.videojs(this.video, {
        //   sources: [
        //     {
        //       src: this.url || ""
        //     }
        //   ], 
        //   controls: false, 
        //   autoplay: this.playning, 
        //   preload: "metadata",
        //   with: "100%",
        //   height: "100%"
        // });
      }
      else {
        // if((this.player.volume*100) !== this.volumen) {
        //   this.player.volume = (this.volumen / 100);
        //   if((this.player.volume*100) !== this.volumen) {
        //     this.onVolumen.emit((this.player.volume*100))
        //   }
        // }
        if(this.lastUrl !== this.url) {
          this.player.destroy()
          this.player = mpegts.createPlayer({
            type: "mpegts",
            isLive: true,
            url: this.url
          });
          this.player.attachMediaElement(this.video);
          this.player.load();
          this.player.play();
          console.log("********************")
          console.log("********************")
          console.log("********************")
        }
        // if(this.muted !== this.video.muted) this.video.muted = this.muted;
        // if(this.player.isPlaying !== this.playning) {
        //   if(this.playning) this.player.play();
        //   else this.player.pause();
        // }
      }
      this.lastUrl = this.url;
      
      if(this.showPicture) {
        if((document as any).pictureInPictureElement !== this.video) {
          // if((document as any).pictureInPictureElement) await (document as any).exitPictureInPicture();
          if(this.showPicture) {
              await (this.video as any).requestPictureInPicture();
          }
        }
      }
      else {
        if((document as any).pictureInPictureElement === this.video) {
          await (document as any).exitPictureInPicture();
        }
      }
      
    }
    else {
      if(this.player) this.Destroy();
    }
  }

  Destroy() {
    if(this.player) {
      // this.player.dispose();
      this.player = null;
    }
  }

  
}
