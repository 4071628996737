// Add your providers here for easy indexing.
export { TranslateProvider } from "./translate/translate.service";

export const OptionMenu = [
  {
      "title": "Fibex Play",
      "url": "/home",
      "direct": "forward",
      "icon": "ios-desktop",
      "show": true,
      "image": 'assets/img/icons/Iconos4.jpg'
  },
  {
      "title": "Servicios",
      "url": "/services",
      "direct": "forward",
      "icon": "easel",
      "show": true,
      "image": 'assets/img/icons/Iconos3.jpg'
  },  
  {
      "title": "Acerca de",
      "url": "/about",
      "direct": "forward",
      "icon": "md-information-circle",
      "show": true,
      "image": 'assets/img/icons/Iconos App (1).jpg'
  }
]
