import { Component, Input, OnInit, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import { ICommandVideoCallback, IRelationPlayer } from '../video-player/video-player.component';

export interface ICommandCallback {
  // (type: "set_muted", muted: boolean): void;
  // (type: "set_volume", volume: number): void;
  (type: "play"): void;
  (type: "pause"): void;
  (type: "skip_forward"): void;
  (type: "skip_backward"): void;
  (type: "toggle_muted"): void;
  (type: "toggle_play"): void;
  (type: "toggle_fullscreen"): void;
  (type: "increase_volume"): void;
  (type: "decrease_volume"): void;
  // (type: "toggle_muted"): void;
  // (type: "next_channel"): void;
  // (type: "prev_channel"): void;
  // (type: "increase_volume"): void;
  // (type: "decrease_volume"): void;
  // (type: "set_volume", percentage: number): void;
}

interface IPlayerData {
  // [showMenuPlayer]="showMenuPlayer && !isGrid && !isChangePlan"
  // urlSource: string | null,
  // isFavorite: boolean,
  // isLocked: boolean,
  loading: boolean,
  volumen: number,
  // blackborders: boolean,
  indexRelation: number,
  playning: boolean,
  muted: boolean,
  timeline: number,
  maxTimeLine: number,
  commandVideo: ICommandVideoCallback
}

type TypeView = "simple" | "doble" | "triple" | "triple-invertido" | "cuatruple" | "triple-vertical";

export interface ICallbackSelectCanal {
  (url: string, isFavorite: boolean, isLocked: boolean)
}

@Component({
  selector: 'app-multi-screen-player',
  templateUrl: './multi-screen-player.component.html',
  styleUrls: ['./multi-screen-player.component.scss'],
})
export class MultiScreenPlayerComponent implements OnInit, OnChanges {
  indexTypePlayer: number = 0;
  @Input() readonly indexPlayer: number = 0;
  public TypePlayer: TypeView = "simple";
  public showPicture: boolean = false;
  public selectTypeView: boolean = false;
  public relation: IRelationPlayer = "auto";
  public silenced: boolean = true;
  public playerData: IPlayerData[] = [
    this.createPlayerData()
  ]

  @Input() players: {
    [key: string]: { url: string[], favorite: boolean, locked: boolean }
  } = { };

  // @Input() url: string | null;
  @Input() showMenuPlayer: boolean = true; // Mostrar (efecto de ocultar / mostrar)
  // @Input() favorite: boolean = false;
  // @Input() locked: boolean = false;
  @Input() fullscreen: boolean = false;
  @Input() isTV: boolean = false; // opcional
  @Input() isMobile: boolean = false; // opcional
  @Input() isBrowser: boolean = false; // opcional
  @Input() showDetails: boolean = false; // opcional
  @Input() typeView: number = 0;

    // salidas
  // @Output() loading: EventEmitter<{index: number, loading: boolean}> = new EventEmitter();
  @Output() onShowInfo: EventEmitter<void> = new EventEmitter();
  @Output() onChangeFavorite: EventEmitter<boolean> = new EventEmitter();
  @Output() onChangeLocked: EventEmitter<boolean> = new EventEmitter();
  @Output() onCommandsCallback: EventEmitter<ICommandCallback> = new EventEmitter();
  @Output() onChangeFullScreen: EventEmitter<boolean> = new EventEmitter();
  @Output() onActivatePlayer: EventEmitter<number> = new EventEmitter();
  // @Output() showListCanal: EventEmitter<void> = new EventEmitter();
  // @Output() initPlayerCallback: EventEmitter<(indexPlayer: number, url: string) => void> = new EventEmitter();
  @Output() onChangeLengthPlayer: EventEmitter<{length: number, type: number}> = new EventEmitter();
  @Output() nextChannel: EventEmitter<void> = new EventEmitter();
  @Output() backChannel: EventEmitter<void> = new EventEmitter();
  @Output() error: EventEmitter<number> = new EventEmitter();
  @Output() onPlayningUrl: EventEmitter<{index: number, url: string}> = new EventEmitter();

  indexRelation: number = 0;
  relations: any[] = [
    "auto",
    {
      display: "full",
      x: 1,
      y: 1
    },
    {
      display: "4:3",
      x: 4,
      y: 3
    },
    {
      display: "2:3",
      x: 2,
      y: 3
    },
    {
      display: "3:2",
      x: 3,
      y: 2
    },
    {
      display: "16:9",
      x: 16,
      y: 9
    },
  ] as IRelationPlayer[];

  constructor() { }

  handlerPlay() {
    if(this.playerData.length === 1) {
      const player = this.playerData[this.indexPlayer];
      
      if(player) {
        player.playning = !player.playning;
      }
    }
  }

  ngOnInit() {
    addEventListener("leavepictureinpicture", (event) => {
      this.showPicture = false;
    });
    this.handlerSetTypeView(this.typeView || 0);
    const QuitMuted = () => {
      document.removeEventListener("keydown", QuitMuted);
      document.removeEventListener("click", QuitMuted);

      this.silenced = false;
    }
    document.addEventListener("keydown", QuitMuted);
    document.addEventListener("click", QuitMuted);
    
    const callbackPlayer: ICommandCallback = (type: "play" | "pause" | "skip_forward" | "skip_backward" | "toggle_muted" | "toggle_play" | "toggle_fullscreen" | "increase_volume" | "decrease_volume") => {
      const playerActive = this.playerData[this.indexPlayer];

      if(playerActive) {
        switch(type) {
          case "play":
            playerActive.playning = true;
            break;

          case "pause":
            playerActive.playning = false;
            break;

          case "toggle_play":
            playerActive.playning = !playerActive.playning;
            break;

          case "toggle_muted":
            playerActive.muted = !playerActive.muted;
            break;

          case "decrease_volume":
            this.handlerOnDescreaseVolume();
            break;

          case "increase_volume":
            this.handlerOnIncreaseVolume();
            break;

          case "skip_backward":
            this.handlerOnSkipForward();
            break;

          case "skip_forward":
            this.handlerOnSkipForward();
            break;

          case "toggle_fullscreen":
            this.onChangeFullScreen.emit(!this.fullscreen);
            break;
        }
      }
    }
    
    document.addEventListener("keydown", (ev: KeyboardEvent) => ev.key === "h" && this.click())
    
    this.onCommandsCallback.emit(callbackPlayer);
    // this.inicializePlayerActive();
    // this.initPlayerCallback.emit((index: number, url: string) => {
    //   if(this.playerData[index]) {
    //     this.playerData[index].urlSource = url;
    //   }
    // })
  }

  ngOnChanges(changes: SimpleChanges): void {
    // changes["type"]
    if(changes["typeView"].previousValue !== changes["typeView"].currentValue) {
      this.handlerSetTypeView(this.typeView || 0);
    }
  }
  // ngOnChanges() {
  //   console.log(this.getActiveSource().url);
  //   // this.inicializePlayerActive();
  // }

  getActiveSource(index?: number) {
    return this.players[index !== undefined && index !== null ? index : this.indexPlayer] || {
      url: [],
      favorite: false,
      locked: false
    };
  }

  createPlayerData(): IPlayerData {
    return {
      // urlSource: null,
      // isFavorite: false,
      // isLocked: false,
      loading: true,
      indexRelation: 0,
      maxTimeLine: 100,
      muted: true,
      playning: true,
      timeline: 0,
      volumen: 100,
       commandVideo() {
        return null;
      }
    }
  }
  HandlerClickPlayer(indexPlayer: number) {
    console.log("CLICK PLAYER:")
    if(this.playerData.length === 1) {
      this.playerData[0].playning = !this.playerData[0].playning;
    }
    else {
      this.onActivatePlayer.emit(indexPlayer);
    }
    
  }
  // inicializePlayerActive() {
  //   const playerActive = this.getPlayerActive();

  //   if(playerActive.isFavorite !== this.favorite) playerActive.isFavorite = this.favorite;
  //   if(playerActive.isLocked !== this.locked) playerActive.isLocked = this.favorite;
  //   if(playerActive.urlSource !== this.url) playerActive.urlSource = this.url;
  // }
  getPlayerActive(): any {
    // return this.playerData[this.in]
    // if(this.indexActive >= this.playerData.length) this.indexActive = this.playerData.length-1;
    return this.playerData[this.indexPlayer] || {
      commandVideo() {return 0},
      indexRelation: 0,
      loading: false,
      maxTimeLine: 0,
      timeline: 0,
      volumen: 0,
      muted: true,
      playning: false,
    };
  }
  handlerLoadingPlayer(index: number, loading: boolean) {
    
    // this.loading.emit({index, loading});
  }
  handlerShowInfo() {
    this.onShowInfo.emit();
  }
  handlerSetTypeView(type: number) {
    this.indexTypePlayer = type;
    // this.loading.emit({index: type, loading: false});
    switch(type) {
      case 0:
        this.TypePlayer = "simple";
        this.playerData = [...this.playerData].slice(0, 1);
        break;

      case 1:
        this.TypePlayer = "doble";
        this.playerData = [...this.playerData, this.createPlayerData()].slice(0, 2);
        break;

      case 2:
        this.playerData = [...this.playerData, this.createPlayerData(), this.createPlayerData()].slice(0, 3)
        this.TypePlayer = "triple";
        break;

      case 3:
        this.playerData = [...this.playerData, this.createPlayerData(), this.createPlayerData()].slice(0, 3)
        this.TypePlayer = "triple-invertido";
        break;

      case 4:
        this.playerData = [...this.playerData, this.createPlayerData(), this.createPlayerData(), this.createPlayerData()].slice(0, 4)
        this.TypePlayer = "cuatruple";
        break;

      case 5:
        this.playerData = [...this.playerData, this.createPlayerData(), this.createPlayerData()].slice(0, 3)
        this.TypePlayer = "triple-vertical";
        break;

      default:
        throw new Error(`Rango de tipos inválido, recibido: ${type}, rango aceptado: 0-5`);
    }

    // if(this.indexPlayer >= this.playerData.length) {
    //   this.onChache
    // }

    this.onChangeLengthPlayer.emit({length: this.playerData.length, type: type});
  }
  clickI = 0;
  click() {
    this.handlerSetTypeView(this.clickI++);
    if(this.clickI > 5) this.clickI = 0;
  }









  handlerOnChangeMuted() {
    const player = this.getPlayerActive();
    player.muted = !player.muted;
  }
  handlerOnSkipBackward() {
    const player = this.getPlayerActive();
    player.commandVideo("setTimeLine", player.commandVideo("getTimeLine") - 10);
  }
  handlerOnSkipForward() {
    const player = this.getPlayerActive();
    player.commandVideo("setTimeLine", player.commandVideo("getTimeLine") + 10);
  }
  handlerOnChangePlay() {
    const player = this.getPlayerActive();
    player.playning = !player.playning;
  }
  handlerOnIncreaseVolume() {
    const player = this.getPlayerActive();
    player.volumen += 10;
    if(player.volumen > 100) player.volumen = 100;
  }
  handlerOnDescreaseVolume() {
    const player = this.getPlayerActive();
    player.volumen -= 10;
    if(player.volumen < 0) player.volumen = 0;
  }
  handlerOnChangeTimeLine(time: number) {
    const player = this.getPlayerActive();
    player.commandVideo("setTimeLine", time);
  }
  idTimerShowInfo: any;
  showInfo:boolean = false;
  handlerOnChangeSliceBlackBorders() {
    const player = this.getPlayerActive();
    if(++player.indexRelation >= this.relations.length) {
      player.indexRelation = 0;
    }
    this.showInfo = true;
    if(this.idTimerShowInfo) clearTimeout(this.idTimerShowInfo);
    this.idTimerShowInfo = setTimeout(() => {
      this.idTimerShowInfo = null;
      this.showInfo = false;
    }, 8000);
  }
  handlerOnChangeShowPicture() {
    this.showPicture = !this.showPicture;
    setTimeout(() => {
      if(!(document as any).pictureInPictureElement) this.showPicture = false;
    }, 400);
  }
}
