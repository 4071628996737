import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { IFibexGames } from '../../services/consulta.games.fibex.service';
// import { ConsultaGamesFibexService } from '../../services/consulta.games.fibex.service';
// import {  Router,ActivatedRoute } from '@angular/router';
// import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import axios from 'axios';

@Component({
  selector: 'app-game-view',
  templateUrl: './game-view.component.html',
  styleUrls: ['./game-view.component.scss'],
})
export class GameViewComponent implements OnInit { 
  // @Pipe({
  //   name: 'safe'
  // })
  @Input() gameSelected: IFibexGames = {} as any;
  @Output() goToBack: EventEmitter<void> = new EventEmitter();
  url: string | undefined;
  urlSafe: SafeResourceUrl | undefined;
  loadingGame: boolean = true;
 
  constructor(
    // private gamesServices:ConsultaGamesFibexService , 
    // private router:Router ,
    // private activatedroute :ActivatedRoute,
    private sanitizer: DomSanitizer
  ) { }
  // params = this.activatedroute.snapshot.params;
  ngOnInit() {
    console.log(">>>>>>>> >>>>>>>>>>>>>>>>>>>>> >>>>>>>>>>>>>>>>>>>", this.gameSelected);
    console.log("INIT");
    this.loadingGame = true;
  }

  ngOnChanges() {
    console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> CHANGE GAME");
  }

  ngOnDestroy() {
    console.log(">>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>  DESTROY GAME");
  }

  transform(url:string) {
    if (url !== this.url){ 
      this.url = url;

      // axios.get(this.url, {
      //   headers: {
      //     origin: this.gameSelected.Url
      //   },
      //   responseType: 'blob'
      // })
      // .then((res) => {
      //   if(res.status == 200) {
      //     console.log("CARGADO... ")
      //     var data_url = URL.createObjectURL(res.data);
      //     this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(data_url);
      //   }
      // })
      // .catch((err) => {
      //   console.error(err);
      // })
      this.urlSafe = this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
      console.log('$$$$$$$$URL nuevo', this.urlSafe);
    }
    else {
      console.log('$$$$$$$$URL ES IGUAL', this.urlSafe)
    }
    return this.urlSafe;
  }

  stopEvent(ev: any) {
    ev.preventDefault();
    ev.stopPropagation();
  }
}
