import { Injectable } from "@angular/core";


@Injectable({
    providedIn: 'root'
  })
export class ResolucionService {

    Plataforma: any = navigator.userAgent.toLowerCase().replace(/\W+/g, "")
    // use: any
    // categoria: string = ""

    GetPlataforma(): "smarttv" | "macos" | "iphone" | "ipad" | "ipod" | "blackberry" | "android" | "webos" | "windowsphone" | "windows" | "firetv" | "mac" |"linux" {
        const platform = this.Plataforma.match(/smarttv/i)
            || this.GetFireTV()
            || this.Plataforma.match(/iphone/i)
            || this.Plataforma.match(/ipad/i)
            || this.Plataforma.match(/ipod/i)
            || this.Plataforma.match(/blackberry/i)
            || this.Plataforma.match(/android/i)
            || this.Plataforma.match(/webos/i)
            || this.Plataforma.match(/windowsphone/i)
            || this.Plataforma.match(/windows/i)
            || this.Plataforma.match(/mac/i)
            || this.Plataforma.match(/linux/i)

        return (platform && platform[0]) || "browser";
    }

    GetTypeDispositivo(): "desktop" | "tv" | "phone" {
        const platform = this.GetPlataforma();

        if (platform) {
            switch (platform) {
                case "linux":
                case "mac":
                case "windows":
                case "macos":
                    return "desktop";

                case "smarttv":
                case "webos":
                case "firetv":
                    return "tv";

                case "android": // moviles
                case "blackberry":
                case "windowsphone":
                case "ipod": // tables
                case "ipad":
                case "iphone":
                    return "phone"
            }
        }
    }

    private GetFireTV() {
        if (this.Plataforma.includes("android")) {
            const models = [
                "AFTKA002",
                "AFTKAUK002",
                "AFTHA004",
                "AFTLBT962E2",
                "AEOHY",
                "AFTTIFF43",
                "AFTGAZL",
                "AFTANNA0",
                "AFTHA001",
                "AFTMON001",
                "AFTMON002",
                "AFTJULI1",
                "AFTANNA0",
                "AFTHA003",
                "AFTKA",
                "AFTLFT962X3",
                "AFTTIFF43",
                "AFTTI43",
                "AFTHA001",
                "AFTPR001",
                "AFTBU001",
                "AFTWMST22",
                "AFTTIFF55",
                "AFTWI001",
                "AFTSSS",
                "AFTSS",
                "AFTDCT31",
                "AFTDCT31",
                "AFTT",
                "AFTBAMR311",
                "AFTEAMR311",
                "AFTKMST12",
                "AFTLE",
                "AFTR",
                "AFTEUFF014",
                "AFTEU014",
                "AFTSO001",
                "AFTMM",
                "AFTEU011",
                "AFTJMST12",
                "AFTA",
                "AFTMM",
                "AFTT",
                "AFTRS",
                "AFTN",
                "AFTS",
                "AFTM",
                "AFTB",
                "AFTMM",
                "AFTHA002",
            ].map(i => i.toLowerCase());

            const result = models.find(m => this.Plataforma.includes(m));

            if (result) return ["firetv"];
        }
    }

}