import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { VideoPlayerComponent } from './video-player/video-player.component';
import { VideoPlayerVideoJSComponent } from './video-player-videojs/video-player-videojs.component';
import { VideoPlayerSldpComponent } from './video-player-sldp/video-player-sldp.component';
import { MenuPlayerComponent } from './menu-player/menu-player.component';
import { VolumePlayerComponent } from './volume-player/volume-player.component';
import { IonicModule } from '@ionic/angular';
import { environment } from '../../environments/environment';
import { GameListComponent } from './game-list/game-list.component';
import { GameViewComponent } from './game-view/game-view.component';
import { FormsModule } from '@angular/forms';  // <<<< import it here
import { SelectPlayerViewComponent } from './select-player-view/select-player-view.component';
import { MultiScreenPlayerComponent } from './multi-screen-player/multi-screen-player.component';
import { VideoPlayerNativeComponent } from './video-player-native/video-player-native.component';
import { VideoPlayerHlsComponent } from './video-player-hls/video-player-hls.component';
import { VideoPlayerHttpStreamingComponent } from './video-player-http-streaming/video-player-http-streaming.component';


@NgModule({
  declarations: [
    VideoPlayerComponent,
    VideoPlayerVideoJSComponent,
    VideoPlayerSldpComponent,
    VideoPlayerNativeComponent,
    MenuPlayerComponent,
    VolumePlayerComponent,
    GameListComponent,
    GameViewComponent,
    SelectPlayerViewComponent,
    MultiScreenPlayerComponent,
    VideoPlayerHlsComponent,
    VideoPlayerHttpStreamingComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule.forRoot(environment.config),
  ],
  exports: [
    VideoPlayerComponent,
    VideoPlayerVideoJSComponent,
    VideoPlayerSldpComponent,
    VideoPlayerNativeComponent,
    MenuPlayerComponent,
    VolumePlayerComponent,
    GameListComponent,
    GameViewComponent,
    SelectPlayerViewComponent,
    MultiScreenPlayerComponent,
    VideoPlayerHlsComponent,
    VideoPlayerHttpStreamingComponent
  ],
})
export class ComponentsModule { }
