import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ConsultaGamesFibexService } from '../../services/consulta.games.fibex.service';
// import {GamesService} from '../../services/games.service'
import { Pipe } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { IFibexGames } from '../../services/consulta.games.fibex.service';

@Component({
  selector: 'app-game-list',
  templateUrl: './game-list.component.html',
  styleUrls: ['./game-list.component.scss']
})
export class GameListComponent implements OnInit {
  @Pipe({
    name: 'safe'
  }) pipe;
  games:IFibexGames[] = []
  loading: boolean = false;

  public gameSelected: IFibexGames | null = null;

  @Output() showGame: EventEmitter<boolean> = new EventEmitter();
  @Output() handlerShow: EventEmitter<() => void> = new EventEmitter();
  

  constructor(private gamesServices:ConsultaGamesFibexService,private sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.handlerShow.emit(() => {
      this.gameSelected = null;
    })
    this.getgames();
  }

  onErrorGame(game: IFibexGames) {
    setTimeout(() => {
      game.Imagen = "../../../assets/icons/nofound-game-image.png";
    }, 1000);
  }
  
  getgames(){
    this.loading = true;
    this.gamesServices.GetAllGames()
      .then((res) => {
        this.loading = false;
        this.games = res;
        console.log(this.games);
      })
      .catch(err => {
        console.error(err);
        setTimeout(() => this.getgames(), 2000);
      })
  }

  deletegames(id_Juego:number){
    console.log("Eliminando juego "+id_Juego);
    /*this.gamesServices.deleteGame(id_Juego).subscribe(
      res => {
        console.log(res);
        this.getgames();
      },
      err => console.error(err)
    )*/
  }

  updategames(id_Juego:number){
      console.log("Actualizando juego "+id_Juego);
  }

  urls: {[key: string]: {url: string, safeUrl: SafeResourceUrl}} = {};

  transform(url:string, id: string) {
    if(this.urls[id]) {
      // Exite la url, verificamos si la url es distinta
      if(this.urls[id].url !== url) {
        this.urls[id].url = url;
        this.urls[id].safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      }
    }
    else {
      // No existe, en este caso vamos a crear uno nuevo
      this.urls[id] = {
        url: url,
        safeUrl: this.sanitizer.bypassSecurityTrustResourceUrl(url)
      }
    }
    return this.urls[id].safeUrl;
  }

  getStyle(game: IFibexGames) {
    return `background-color: url(${game.Imagen})`;
  }

  handlerClick(game: IFibexGames) {
    this.gameSelected = game;
    this.showGame.emit(true);
  }

  handlerCloseGame() {
    this.gameSelected = null;
    this.showGame.emit(false);
  }
}
