import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import Hls from 'hls.js';
import { StremingChannel } from '../../../interfaces/streamingChannel';
import { UserAuthenticationService } from '../../../services/user-authentication.service';
import { ConsultasService } from '../../../services/consultas.service';
import { SubscriptionGraphqlService } from "../../../services/subscription-graphql.service";
import gql from "graphql-tag";
import { GuiaProgramacionService } from '../../../services/guia-programacion.service';
import { LocalStorageService } from '../../../services/local-storage.service';
import { ConsultaVideoService } from '../../../services/consulta-video.service';
import {
  trigger,
  state,
  style,
  animate,
  transition
} from '@angular/animations';
@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.page.html',
  styleUrls: ['./video-player.page.scss'],
  animations: [
    trigger('fadein', [
      state('void', style({ opacity: 0 })),
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-out', style({ opacity: 1 }))
      ]),
      transition(':leave', [
        animate('300ms ease-in', style({ opacity: 0 }))
      ]),
    ]),
    trigger('slidelefttitle', [
      state(
        'true',
        style({
          opacity: 1, transform: 'translateY(0%)', visibility: 'visible'
        })
      ),
      state(
        'false',
        style({
          opacity: 0, transform: 'translateY(100%)', visibility: 'hidden'
        })
      ),
    ]),
    transition('false<=>true', [
      animate(300),
    ]),
    trigger('itemState', [
      state(
        'void',
        style({
          transform: 'translateX(-100%)',
        })
      ),
      transition('void => *', animate('500ms ease-out')),
    ]),
    trigger('itemStateMenu', [
      transition('void => *', [
        style({ transform: 'translateX(-100%)' }),
        animate('500ms ease-out'),
      ]),
      transition('* => void', [
        animate('500ms ease-in', style({ transform: 'translateX(100%)' })),
      ]),
    ]),
    trigger('splash', [
      transition(':leave', [
        animate('900ms ease-in', style({ opacity: 0 }))
      ])
    ]),
    trigger('round', [
      transition(':enter', [
        animate('3000ms ease', style({ transform: 'rotateY(360deg)' }))
      ]),
      transition(':leave', [
        animate('900ms ease-in', style({ opacity: 0 }))
      ])
    ])
  ]
})
export class VideoPlayerPage implements OnInit, OnDestroy {
  public data: any;
  public hls: Hls;
  public interval: NodeJS.Timeout;
  private user: any;
  public ipUser: any; //= localStorage.getItem('ipUser');
  public title: string;
  public channelGuide: any;
  public mute: boolean = false;
  public play: boolean = true;
  private isFullscreen: boolean = false;
  public video: any = null;
  public volumeTrack: any = null;
  public changeForm: boolean = false;
  public isShowingThumb: boolean = false;
  public error: boolean = false;
  public dayPointer: string = '';
  public daysArr: string[];
  public deuda: string | null = null;
  public videoLoading: boolean = true;
  public bar: boolean = false;
  public ShowMenu: boolean = true;
  public showSplash: boolean = false;
  constructor(
    private modal: ModalController,
    private consultService: ConsultasService,
    private userAuthService: UserAuthenticationService,
    private _SubscriptionGraphqlService: SubscriptionGraphqlService,
    private _localStorageService: LocalStorageService,
    private guia: GuiaProgramacionService,
    private consultaVideo: ConsultaVideoService,
    public navCtrl: NavController,
  ) {
    if (this.consultaVideo.video) {
      this.data = this.consultaVideo.video
    } else {
      this.navCtrl.navigateRoot('home')
    }

    this.user = this.userAuthService.getUser();
    //
    this.consultService.GetIpDevice().then((ResIP: any) => {
      this.ipUser = ResIP
    })

  }

  ngOnInit() {
    this.showSplashScreen();
    this.getSaldo();
    this.getGuia();
    this.init();
  }

  ngOnDestroy(): void {
    clearInterval(this.interval);
  }

  conversor(monto: any) {
    return `${parseFloat(monto).toFixed(2).replace("-", "")}$`
  }

  getSaldo() {
    try {
      const saldoStorage = this._localStorageService.get('saldoStorage') || null;
      if (parseFloat(saldoStorage[0].saldo) < 0) {
        if (new Date().getDate() < 13) {
          return;
        }
        const time = this._localStorageService.get("lastDeudaShown");
        const diff = this.diff_hours(time);
        if (diff < 1) {
          return;
        }
        this.deuda = saldoStorage[0].saldo;
        setTimeout(() => {
          this.deuda = null;
        }, 60000)
        this._localStorageService.set("lastDeudaShown", new Date());
      }
    } catch (error) {
      this.deuda = null;
    }
  }

  diff_hours(dt2) {
    if (!dt2) {
      return 100;
    }
    let diff = (new Date(dt2).getTime() - new Date().getTime()) / 1000;
    diff /= (60 * 60);
    return Math.abs(diff);
  }

  init() {
    try {

      const that = this;
      const videoContainer: any = document.querySelector('.video-container')
      /* ;*/
      let video = document.getElementById('video-hls') as HTMLMediaElement;
      if (Hls.isSupported()) {
        this.hls = new Hls()
        this.title = this.data.channel_title;
        this.hls.loadSource(this.data.source);
        this.hls.attachMedia(video);
        this.hls.on(Hls.Events.MEDIA_ATTACHED, function () {
          video.muted = false;
          video.play();
        });
        video.addEventListener('loadeddata', function () {
          setTimeout(() => {
            that.videoLoading = false;
            that.handleFullscreen()
          }, 500);
          that.volumeTrack = document.querySelector('.volume-track');
          that.volumeTrack.style.height = `${that.video.volume * 100}%`;
        })
        this.timerUserWatchingStreaming();
      } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
        video.src = 'https://test-streams.mux.dev/x36xhzz/x36xhzz.m3u8';
        video.addEventListener('canplay', function () {
          video.play();
        });
        this.timerUserWatchingStreaming();
      }
      this.video = document.getElementById('video-hls') as HTMLMediaElement;

    } catch (error) {
      console.error(error)
    }
  }

  goBack() {
    this.navCtrl.navigateRoot('home')
  }

  getGuia() {
    try {
      const dataGuide = this.guia.getData();
      const guide = !!this.data.guide_id ? dataGuide.tv.programme[this.data.guide_id] : null;
      if (!!guide) {
        const dateObj = new Date()
        const month = dateObj.getMonth() + 1; //months from 1-12
        const day = dateObj.getDate();
        const year = dateObj.getFullYear();
        const date = `${month}/${day}/${year}`;
        this.daysArr = Object.keys(guide.programmeItems);
        this.dayPointer = guide.programmeItems[date] ? date : this.daysArr[this.daysArr.length - 1];
      }
      this.channelGuide = guide;
      if (guide) {
        setTimeout(() => {
          this.setCarrousellPosition();
        }, 300)
      }

    } catch (error) {
      this.channelGuide = null;
      console.error(error)
    }

  }
  public ChangeShowMenu(info: "over" | "out" | "change" | "click"): void {
    // if(this.ShowMenu === true) {
    //   this.ShowMenu = true;
    // } else if(this.ShowMenu === false) {
    //   this.ShowMenu = true;
    // }
    switch (info) {
      case 'over':
        this.ShowMenu = true;
        break;
      case 'out':
        this.ShowMenu = false;
        break;
      case 'change':
        this.ShowMenu = !this.ShowMenu;
        break;
      case 'click':
        this.ShowMenu = false;
        break;
    }
  }

  selectDay(day: string) {
    if (this.dayPointer !== day) {
      this.dayPointer = day;
      this.setCarrousellPosition();
      const target = document.querySelector('.grid-items-container');
      target.scrollTop = 0;
    }
  }

  setCarrousellPosition() {
    try {

      const container = document.querySelector('.days-container');
      const position = this.daysArr.indexOf(this.dayPointer);
      const target: any = container.children[position];
      if (target) {
        if (position >= 0 || position < this.daysArr.length) {
          target.parentElement.parentElement.scrollLeft = target.offsetLeft + target.clientLeft - 56;
        }

      }

    } catch (error) {
      console.error(error)
    }
  }

  handleNextPosition() {
    try {
      const currentPosition = this.daysArr.indexOf(this.dayPointer);
      if (currentPosition <= this.daysArr.length - 2) {
        this.dayPointer = this.daysArr[currentPosition + 1];
        this.setCarrousellPosition()
      }
    } catch (error) {
      console.error(error)
    }
  }

  hanldePrevPosition() {
    try {

      const currentPosition = this.daysArr.indexOf(this.dayPointer);
      if (currentPosition >= 1) {
        this.dayPointer = this.daysArr[currentPosition - 1];
        this.setCarrousellPosition()
      }

    } catch (error) {
      console.error(error)
    }
  }

  hanldeMute() {

    if (this.mute) {
      this.mute = false;
    } else {
      this.mute = true;
    }
    this.video.muted = this.mute;
  }

  hanldePlay() {
    if (this.video.paused || this.video.ended) {
      this.play = true;
      this.video.play();
    } else {
      this.play = false;
      this.video.pause();
    }

  }

  handleFullscreen() {
    if (this.isFullscreen) {
      this.bar = false;
      // document.getElementById('mute').style.opacity='1'
      // document.getElementById('volinc').style.opacity='1'
      // document.getElementById('voldec').style.opacity='1'
      // document.getElementById('aplify').style.opacity='1'
      // document.getElementById('fs').style.opacity='1'
      this.closeFullscreen();
    } else {
      this.bar = true;
      // document.getElementById('mute').style.opacity='0'
      // document.getElementById('volinc').style.opacity='0'
      // document.getElementById('voldec').style.opacity='0'
      // document.getElementById('aplify').style.opacity='0'
      // document.getElementById('fs').style.opacity='0'
      this.openFullscreen();

    }
  }

  toggleBar() {
    const vidControl = document.getElementById('mute')
    if (vidControl.style.opacity == '0') {
      document.getElementById('mute').style.opacity = '1'
      document.getElementById('volinc').style.opacity = '1'
      document.getElementById('voldec').style.opacity = '1'
      document.getElementById('aplify').style.opacity = '1'
      document.getElementById('fs').style.opacity = '1'
    } else if (vidControl.style.opacity == '1') {
      document.getElementById('mute').style.opacity = '0'
      document.getElementById('volinc').style.opacity = '0'
      document.getElementById('voldec').style.opacity = '0'
      document.getElementById('aplify').style.opacity = '0'
      document.getElementById('fs').style.opacity = '0'
    }
  }

  openFullscreen() {
    try {

      const videoContainer: any = document.querySelector('.video-container')
      if (videoContainer.requestFullscreen) {
        videoContainer.requestFullscreen();
      } else if (videoContainer.webkitRequestFullscreen) { /* Safari */
        this.video.webkitRequestFullscreen();
      } else if (videoContainer.msRequestFullscreen) { /* IE11 */
        videoContainer.msRequestFullscreen();
      }
      this.ShowMenu = false;
      this.isFullscreen = true;
      // this.changeForm = true;

    } catch (error) {
      console.error(error)
    }
  }

  /* Close fullscreen */
  closeFullscreen() {
    try {
      const doc: any = document;
      if (doc.exitFullscreen) {
        doc.exitFullscreen();
      } else if (doc.webkitExitFullscreen) { /* Safari */
        doc.webkitExitFullscreen();
      } else if (doc.msExitFullscreen) { /* IE11 */
        doc.msExitFullscreen();
      }
      this.ShowMenu = true
      this.isFullscreen = false;
      this.changeForm = false;
    } catch (error) {
      console.error(error)
    }
  }

  increaseVolume() {
    try {

      if (this.video.volume < 1) {

        this.video.volume = this.parseDecimal(this.video.volume + 0.05);
      }
      this.handleVolume();

    } catch (error) {
      console.error(error)
    }
  }

  decreaseVolume() {
    try {
      if (this.video.volume > 0) {
        this.video.volume = this.parseDecimal(this.video.volume - 0.05);
      }
      this.handleVolume();
    } catch (error) {
      console.error(error)
    }
  }

  handleVolume() {
    try {
      if (!this.isShowingThumb) {
        this.isShowingThumb = true;
        setTimeout(() => {
          this.isShowingThumb = false;
        }, 2000)
      }
      this.volumeTrack.style.height = `${this.video.volume * 100}%`;
    } catch (error) {
      console.error(error)
    }
  }

  parseDecimal(float: number) {
    return Math.round(float * 100) / 100;
  }

  closeModal() {
    try {
      this.modal.dismiss();
      if (!navigator.userAgent.toLocaleLowerCase().includes('android' || 'iphone' || 'ipad' || 'webos')) {
        this.hls.destroy();
      }
      clearInterval(this.interval);
    } catch (error) {
      console.error(error)
    }
  }

  fancyTimeFormat(duration: number) {
    // Hours, minutes and seconds
    let hrs = Math.floor(duration / 3600);
    let mins = Math.floor((duration % 3600) / 60);
    let secs = Math.floor(duration % 60);

    // Output like "1:01" or "4:03:59" or "123:03:59"
    let ret = "";

    if (hrs > 0) {
      ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
    }

    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;
    return ret;
  }

  formatDate(dateString: string) {
    const date = new Date(dateString);
    const options: any = { weekday: 'long', month: 'long', day: 'numeric' };
    const string = Intl.DateTimeFormat('es-ES', options).format(date);
    return `${string}`.replace('de', '-');
  }

  formatMinutesHours(dateString: string) {
    const date = new Date(dateString);
    const hours = date.getHours();
    const minutes = date.getMinutes();

    return `${hours < 10 ? `0${hours}` : hours}:${minutes < 10 ? `0${minutes}` : minutes}`
  }

  handleChange() {
    this.changeForm = !this.changeForm;
  }

  timerUserWatchingStreaming() {
    try {
      console.log("Estoy en video-player")
      console.log(this.data)
      this.interval = setInterval(() => {
        this.consultService.SolicitudPermisoTv({
          idDevice: "0000000",
          Documento: this.user.identidad,
          Canal: this.data.channel_title,
          Plataforma: "tvbox",
          IP: this.ipUser || ''
        }).then((res) => {

        })
      }, 300000);

    } catch (error) {
      console.error(error)
    }
  }

  showSplashScreen() {
    this.showSplash = true;
    setTimeout(() => {
      this.showSplash = false;
    }, 3000);
  }

  skipBackward() {
    let currentTime = this.video.currentTime
    this.video.currentTime = currentTime - 2;
  }
  skipForward() {
    let currentTime = this.video.currentTime
    this.video.currentTime = currentTime + 2;
  }
}