import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  ViewChild,
} from "@angular/core";
import { AlertController, ModalController } from "@ionic/angular";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { UserAuthenticationService } from "../../../services/user-authentication.service";
import { ConsultasService } from "../../../services/consultas.service";
import { LocalStorageService } from "../../../services/local-storage.service";

@Component({
  selector: "app-forgot-password-page",
  templateUrl: "./forgot-password-page.component.html",
  styleUrls: ["./forgot-password-page.component.scss"],
})
export class ForgotPasswordPageComponent implements OnInit, AfterViewInit {
  constructor(
    private modalCtrl: ModalController,
    private _formBuilder: FormBuilder,
    private authentication: UserAuthenticationService,
    private consulta: ConsultasService,
    private _localStorageService: LocalStorageService,
    public alertCtrl: AlertController,
    public formBuilder: FormBuilder
  ) {}

  public verifyPinForm: FormGroup;
  public patternNum = "[0-9]+";
  public patternEmail = "[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$";
  public showPassword: boolean = false;
  _user: any;
  _dni: boolean = false;

  ngOnInit() {
    this.verifyPinForm = this.formBuilder.group({
      dni: ["", Validators.compose([Validators.required])],
      email: ["", Validators.compose([Validators.required, Validators.email])],
      phone: [
        "",
        Validators.compose([Validators.required, Validators.minLength(4)]),
      ],
    });
    this.verifyLocalStorage()
  }

  ngAfterViewInit() {}

  closeModal() {
    this.modalCtrl.dismiss("cancel");
  }

  verifyDNI() {
    let dni = this.verifyPinForm.value["dni"];
    let DataUser: any, DataUserCRM: any;
    this.consulta
      .ClienteCRM(dni)
      .then((ResCRM: any) => {
        DataUserCRM = ResCRM;
        this._user = DataUserCRM;
        if (DataUserCRM.status === true) {
          this._dni = true;
          this.verifyPinForm.patchValue({
            email: DataUserCRM.email,
            phone: DataUserCRM.phonenumber,
          });
        } else {
          this.denied('ced');
        }
      })
      .catch((err: any) => {
        console.error(err);
      });
  }

  async denied(form:string) {
    let tittle
    let message
    switch (form) {
      case 'ced':
          tittle = 'Cedula invalida',
          message = 'La cedula ingresada no se encuentra registrada'
        break;
      // case 'email':
      //   tittle = 'Email invalida',
      //   message = `El email registrado a la cedula ${this._user.cedula}`
      // break;

      default:
        break;
    }

    const alert = await this.alertCtrl.create({
      header: tittle,
      message: message,
      buttons: [
        {
          text: "Cancelar",
          role: "cancel",
          cssClass: "secondary",
        },
      ],
    });
    await alert.present();
  }

  //Only numbers in the input DNI
  keyPressNumbers(event) {
    var charCode = event.which ? event.which : event.keyCode;
    // Only Numbers 0-9
    if (charCode < 48 || charCode > 57) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }

  handleShowPassword() {
    if (this.showPassword) {
      this.showPassword = false;
    } else if (!this.showPassword) this.showPassword = true;
  }

  verifyLocalStorage() {
    try {
      if (this._localStorageService.get("user")) {
        const user = this.authentication.getUser();
        this.verifyPinForm.patchValue({
          dni: user.identidad,
        });
      }
    } catch (err) {
      console.error(err);
    }
  }

  verifyEmailPhone(){
    let user = this.verifyPinForm.value

      if(user.email != this._user.email){
        // ('denied email baby')
      }
      if( user.phone != this._user.phonenumber){
       //('denied phone baby')
      }
  }
}
