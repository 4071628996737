import { Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class FavoriteService {

  constructor(
    private _localStorageService: LocalStorageService,
  ) {

   }

   setFavorite(id: string) {
    const favorites = this.getFavorites();
    if (!favorites.includes(id)) {
      this._localStorageService.set('favorites', [...favorites, id]);
      return true;
    }
    const updated = favorites.filter((item: string) => item !== id);
    this._localStorageService.set('favorites', updated);
    return false;
   }

   getFavorites() {
    const favorites = this._localStorageService.get('favorites') || [];
    return favorites;
   }

   isFavorite(id: string): boolean {
    const favorites = this.getFavorites();
    return favorites.some(item => item === id);
   }
}
