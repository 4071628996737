import { Component, Input, OnInit, Output, ViewChild, EventEmitter, ElementRef, OnChanges, SimpleChanges } from '@angular/core';
import { ICommandVideoCallback } from '../video-player/video-player.component';
import { ResolucionService } from '../../services/resolution.service';

@Component({
  selector: 'app-video-player-native',
  templateUrl: './video-player-native.component.html',
  styleUrls: ['./video-player-native.component.scss'],
})
export class VideoPlayerNativeComponent implements OnInit, OnChanges {
  @ViewChild("video", {static: true}) video: ElementRef; 
  
  @Input() volumen: number; // rango del 0 al 100
  @Input() url: string | null; // url del video
  @Input() blackborders: boolean; // eliminar border negros
  @Input() playning: boolean; // reproduciendo
  @Input() muted: boolean; // Muteado
  @Input() showPicture: boolean;

  @Output() onVolumen: EventEmitter<number> = new EventEmitter();
  @Output() callback: EventEmitter<ICommandVideoCallback> = new EventEmitter();
  @Output() currentTime: EventEmitter<{current: number, max: number}> = new EventEmitter();
  @Output() loading: EventEmitter<boolean> = new EventEmitter();
  @Output() error: EventEmitter<void> = new EventEmitter();
  @Output() closePicture: EventEmitter<void> = new EventEmitter();

  public loadingPlayer: boolean = true;
  private idInterval: any | null;
  private currentTimeLine: number = 0;
  private checkCurrentTimeLine: number = 0;
  private canPlayInThisDispositive: boolean;
  constructor(
    public resolucionService: ResolucionService
  ) { 
    this.resolucionService.GetPlataforma();

    switch(this.resolucionService.GetPlataforma()) {
      case "ipad":
      case "iphone":
      case "ipod":
      case "mac":
      case "macos":
        this.canPlayInThisDispositive = true;
        break;

      default:
        this.canPlayInThisDispositive = false;
    }
  }

  ngOnInit() {
    // Verificar si se está moviendo el progresso, en caso que nó tratar de restaurar el player
    this.idInterval = setInterval(() => {
      if(this.playning && !this.loadingPlayer) {
        if(this.currentTimeLine === this.checkCurrentTimeLine) {
          // En este pundo se asume que se ha quedado pegado e intentamos volver a reproducir
          const video = this.getVideoElement();
  
          video.pause();
          video.play();
        }
        else this.checkCurrentTimeLine = this.currentTimeLine;
      }
    }, 2000)
    
    const video = this.getVideoElement();

    video.addEventListener("timeupdate", (ev) => {
      this.currentTimeLine = video.currentTime;
      this.currentTime.emit({current: video.currentTime, max: video.duration});
    })
    video.addEventListener("waiting", (ev) => {
      this.loadingPlayer = true;
      this.loading.emit(true);
    })
    video.addEventListener("canplaythrough", (ev) => {
      this.loadingPlayer = false;
      this.loading.emit(false);
    })
    video.addEventListener("error", (ev: any) => {
      if(ev.target.error.code === MediaError.MEDIA_ERR_SRC_NOT_SUPPORTED) {
        this.error.emit();
      }
    })
    video.addEventListener("volumechange", (ev) => {
      this.onVolumen.emit(video.volume*100);
    })
    video.addEventListener("leavepictureinpicture", (ev) => {
      this.closePicture.emit();
    })

    this.InitVideoNative();
  }

  ngOnDestroy(): void {
    clearInterval(this.idInterval);
  }

  ngOnChanges(): void {
    this.InitVideoNative();
  }
  
  getVideoElement(): HTMLVideoElement {
    return this.video.nativeElement
  }

  InitVideoNative() {
    const video = this.getVideoElement();

    if(video.canPlayType('application/vnd.apple.mpegurl') && this.canPlayInThisDispositive) {
      if(this.url           !== video.src)    video.src = this.url;
      if((this.volumen/100) !== video.volume) {
        video.volume = this.volumen/100;
        if(video.volume !== (this.volumen/100)) this.onVolumen.emit(video.volume);
      }
      if(this.muted         !== video.muted)  video.muted = this.muted;

      // Reproducir video
      if(this.playning !== !video.paused)  {
        if(this.playning) video.play();
        else video.pause();
      }

      // Mostrar picture
      if(this.showPicture) {
        if((document as any).pictureInPictureElement !== this.video) {
          if((document as any).pictureInPictureElement) {
            (document as any).exitPictureInPicture();
          }
          if(this.showPicture) {
            (this.video as any).requestPictureInPicture();
          }
        }
      }
      else {
        if((document as any).pictureInPictureElement === this.video) {
          (document as any).exitPictureInPicture();
        }
      }
    }
    else {
      // No se permite usar este dispositivo como player
      this.error.emit();
    }
  }
}
