import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-volume-player',
  templateUrl: './volume-player.component.html',
  styleUrls: ['./volume-player.component.scss']
})
export class VolumePlayerComponent implements OnInit {
  @ViewChild('volume_element', {static: true}) target: ElementRef;
  @Input() value: number = 0; // rango de 0 a 1

  lastValue: number = 0;
  show: boolean = false;
  timer: NodeJS.Timer | null = null;

  constructor() { 

  }

  // iniciar por primera vez
  ngOnInit() {
    if(this.target) {
      this.target.nativeElement.style.height = `${this.value}%`;
      this.lastValue = this.value;
    }
  }

  // actualizar el tamaño del input
  ngOnChanges() {
    if(this.target) {
      this.target.nativeElement.style.height = `${this.value}%`;

      if(this.lastValue !== this.value) {
        this.show = true; 

        if(this.timer) {
          clearTimeout(this.timer);
        }
        
        this.timer = setTimeout(() => {
          this.show = false;
          this.timer = null;
        }, 3000);
      }
    }
  }
}
