import { Injectable } from '@angular/core';
import { dbFullTable } from '../lib/dbfullv2';

export interface IFibexGames {
    id: string,
    Categoria: string,
    Descripcion: string,
    Imagen: string,
    Nombre: string,
    Url: string,
    tmGamesFibexPlay: string
    createdAt: string,
}

@Injectable({
  providedIn: 'root'
})
export class ConsultaGamesFibexService {
  public tableGames: dbFullTable<IFibexGames>;

  constructor() {
    this.tableGames = new dbFullTable("thomas_cobertura", "tmGamesFibexPlay");
  }

  async GetAllGames(): Promise<IFibexGames[]> {
    return await this.tableGames.GET({type: "find-all-info"});
  }
  async GetGames(id:number|string):Promise<IFibexGames[]> {
    return await this.tableGames.GET({type: "find-in"});
  }
  
} 