import { Component, Input, OnInit, Output, EventEmitter, OnDestroy, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { ResolucionService } from '../../services/resolution.service';
import { ICommandCallback } from '../multi-screen-player/multi-screen-player.component';

export type IRelationPlayer = {
  display: string,
  x: number,
  y: number
} | "auto"

export interface ICommandVideoCallback {
  (type: "getTimeLine"): number;
  (type: "setTimeLine", v: number): void;
  (type: "getMaxTimeLine"): number;
}

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
})
export class VideoPlayerComponent implements OnInit {
  @ViewChild("container", {static: true}) container: ElementRef;

  // Prepara un método para verificar si el player HLS nativo es compatible
  public videoCompatibityHLS = (() => {
    let video = document.createElement("video");
    let compatibility = false;

    switch(this.resolucionService.GetPlataforma()) {
      case "ipad":
      case "iphone":
      case "ipod":
      case "mac":
      case "macos":
        compatibility = Boolean(video.canPlayType('application/vnd.apple.mpegurl'));
        break;
    }
    return () => compatibility;
  })()
  public typePlayer: "videojs" | "sldp" | "native" | "hls" | null;
  public local_volumen: number; // rango del 0 al 100
  public local_playning: boolean; // reproduciendo
  public local_muted: boolean; // Muteado
  public local_timeline: number = 0;
  public local_maxTimeLine: number = 100;
  public local_showPicture: boolean = false;
  public isLive: boolean = false;
  private commandVideo: ICommandVideoCallback = (() => {return 0});
  public errores: string[] = [];
  public loadingPlayer: boolean = true;

  @Input() url: string[] | null;
  @Input() showMenuPlayer: boolean = true; // Mostrar (efecto de ocultar / mostrar)
  @Input() favorite: boolean = false;
  @Input() locked: boolean = false;
  @Input() fullscreen: boolean = false;
  @Input() isTV: boolean = false; // opcional
  @Input() isMobile: boolean = false; // opcional
  @Input() isBrowser: boolean = false; // opcional
  @Input() showDetails: boolean = false; // opcional
  @Input() silenced?: boolean; 

  @Input() _volumen: number;
  @Input() _playning: boolean;
  @Input() _muted: boolean;
  @Input() _timeline: number;
  @Input() _maxTimeLine: number;
  @Input() _showPicture: boolean;
  @Input() _relation: IRelationPlayer;

    // salidas
  @Output() loading: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() onChangeFavorite: EventEmitter<boolean> = new EventEmitter();
  @Output() onChangeLocked: EventEmitter<boolean> = new EventEmitter();
  @Output() onShowInfo: EventEmitter<void> = new EventEmitter();
  @Output() onChangeFullScreen: EventEmitter<boolean> = new EventEmitter();

  // @Output() onCommandsCallback: EventEmitter<ICommandCallback> = new EventEmitter();

  @Output() onVolumen: EventEmitter<number> = new EventEmitter();
  @Output() onBlackborders: EventEmitter<boolean> = new EventEmitter();
  @Output() onPlayning: EventEmitter<boolean> = new EventEmitter();
  @Output() onMuted: EventEmitter<boolean> = new EventEmitter();
  @Output() onTimeline: EventEmitter<number> = new EventEmitter();
  @Output() onMaxTimeLine: EventEmitter<number> = new EventEmitter();
  @Output() callbackVideo: EventEmitter<ICommandVideoCallback> = new EventEmitter();
  @Output() onShowPicture: EventEmitter<boolean> = new EventEmitter();
  @Output() onRelation: EventEmitter<IRelationPlayer> = new EventEmitter();
  @Output() onCommandsCallback: EventEmitter<ICommandCallback> = new EventEmitter();
  @Output() error: EventEmitter<void> = new EventEmitter();
  @Output() onPlayningUrl: EventEmitter<{url: string, indexUrl: number}> = new EventEmitter();

  loadedUrl: boolean = false;
  indexRelation: number = 0;
  relations: IRelationPlayer[] = [
    "auto",
    {
      display: "full",
      x: 1,
      y: 1
    },
    {
      display: "4:3",
      x: 4,
      y: 3
    },
    {
      display: "3:2",
      x: 3,
      y: 2
    },
    {
      display: "16:9",
      x: 16,
      y: 9
    }
  ]

  getRelation() {
    return this._relation ? this._relation : this.relations[this.indexRelation];
  }
  setRelation(re: IRelationPlayer) {
    this.onRelation.emit(re);
    
    let index = this.relations.findIndex(reItem => reItem === re);
    if(index === -1) index = this.relations.push(re)-1;

    this.indexRelation = index;
  }

  lastRelation: unknown;
  safeStyle: SafeStyle;
  GetRelationStyle() {
    let relation: IRelationPlayer = this.getRelation();
    if(this.lastRelation !== relation) {
      this.lastRelation = relation;

      if(relation === "auto") {
        this.safeStyle = this._sanitizer.bypassSecurityTrustStyle("width: 100%; height: 100%;");
      }
      else {
        const maxRelation = Math.max(relation.x, relation.y);
  
        const w = relation.x/maxRelation*100;
        const h = relation.y/maxRelation*100;
        this.safeStyle = this._sanitizer.bypassSecurityTrustStyle(`width: ${w}%; height: ${h}%;`);
      }
    }
    return this.safeStyle;
  }
  
  // Volumen
  get volumen() {
    return typeof this._volumen === "number" ? this._volumen : this.local_volumen;
  }
  set volumen(v: number) {
    this.local_volumen = v;
    this.onVolumen.emit(v);
  }

  // Reproduciendo
  get playning() {
    return typeof this._playning === "boolean" ? this._playning : this.local_playning;
  }
  set playning(v: boolean) {
    if(v !== this.local_playning) {
      this.initSmootingIcon()
    }
    this.local_playning = v;
    this.onPlayning.emit(v);
  }

  // Muteado
  get muted() {
    return typeof this._muted === "boolean" ? this._muted : this.local_muted;
  }
  set muted(m: boolean) {
    this.local_muted = m;
    this.onMuted.emit(m);
  }

  // Progreso del video
  get timeline() {
    return typeof this._timeline === "number" ? this._timeline : this.local_timeline;
  }
  set timeline(t: number) {
    this.local_timeline = t;
    this.onTimeline.emit(t);
  }

  // Maximo progreso del video
  get maxTimeLine() {
    return typeof this._maxTimeLine === "number" ? this._maxTimeLine : this.local_maxTimeLine;
  }
  set maxTimeLine(t: number) {
    this.local_maxTimeLine = t;
    this.onMaxTimeLine.emit(t);
  }

  // show picture
  get showPicture() {
    return typeof this._showPicture === "boolean" ? this._showPicture : this.local_showPicture; 
  }
  set showPicture(s: boolean) {
    this._showPicture = s;
    this.onShowPicture.emit(s);
  }

  // @splash display
  public showSplash: boolean = true; // 0 no display
  public openingSplash: boolean = true; // 0 no display
  public urlSource: string = "";
  public lastUrl: string[] = [];
  public typeOS: string;
  public smootingIcon: boolean;
  public idTimerChangePlay: any;
  public lastPlayning: boolean = false;
  
  constructor(
    public resolucionService: ResolucionService,
    public _sanitizer: DomSanitizer
  ) { 
    this.typePlayer = null;
    this.volumen = 100;
    this.playning = true;
    this.muted = true;
    this.typeOS = resolucionService.GetPlataforma();
  }

  // public transformHlsToWs(url?: string) {
  //   return typeof url === "string" && url.endsWith(".m3u8") && (/^https?:\/\/cdn\w+\.thomas-talk\.me/.test(url) && !/externo/.test(url)) ? url.replace(/^https?/, "wss").replace(/\/\w+\.m3u8$/, "") : url;
  // }

  ngOnInit() {
    const QuitMuted = () => {
      document.removeEventListener("keydown", QuitMuted);
      document.removeEventListener("click", QuitMuted);

      this.muted = false;
    }
    document.addEventListener("keydown", QuitMuted);
    document.addEventListener("click", QuitMuted);
    
    this.InitVideo();
  }

  ngOnChanges() {
    if(this.playning !== this.lastPlayning) {
      this.lastPlayning = this.playning;
      this.initSmootingIcon();
    } 
    
    this.InitVideo();
  }

  indexUrlActive: number = 0;
  dateOfSettedUrls: number = Date.now();
  timeRelativeToCanEmitError: number = 40000; // 40 segundos para permitir emitir el error

  InitVideo() {
    const urls = this.url || [];
    
    if(urls.length !== this.lastUrl.length || urls.some((urlItem, index) => this.lastUrl[index] !== urlItem)) {
      this.loadedUrl = false;
      this.lastUrl = this.url;
      this.indexUrlActive = 0;
      this.handlerChangeLoading(true);

      if(this.timerAutoChangeUrl) {
        clearTimeout(this.timerAutoChangeUrl);
        this.timerAutoChangeUrl = null;
      }
      this.dateOfSettedUrls = Date.now();
      this.urlSource = urls[this.indexUrlActive];
      this.onPlayningUrl.emit({url: this.urlSource, indexUrl: this.indexUrlActive});
    }

    
    if(typeof this.urlSource === "string") {
      if(this.urlSource.startsWith("http")) {
        if(this.videoCompatibityHLS()) {
          // VIDEO NATIVO
          if(this.typePlayer !== 'native') {
            this.typePlayer = "native";
            this.handlerChangeLoading(true);
          }
        }
        else {
          // VIDEO JS
          if(this.typePlayer !== 'videojs' && this.typePlayer !== "hls") {
            this.typePlayer = 'videojs';
            this.handlerChangeLoading(true);
          }
        }        
      }
      // VIDEO SLDP
      else if(this.urlSource.startsWith("ws")) {
        if(this.typePlayer !== 'sldp') {
          this.typePlayer = "sldp";
          this.handlerChangeLoading(true);
        }
      }
      // NINGUNO
      else {
        this.typePlayer = null;
      }
    }
    else this.typePlayer = null;
  }

  handlerError() {
    console.log("ERROR:", this.typePlayer)
    // El reproductor nativo sólo permite ejecutarse en dispositivos móviles actualmente, en caso que no se permita simplemente usar videojs
    if(this.typePlayer === "native") {
      this.AddErrorLog("Reproductor nativo no disponible!")
      this.typePlayer = "videojs";
      this.handlerChangeLoading(true);
    }
    else if(this.typePlayer === "videojs") {
      this.AddErrorLog("Reproductor videojs no disponible!")
      this.typePlayer = "hls";
      this.handlerChangeLoading(true);
    }
    else {
      this.AddErrorLog("Mientras reproducía: " + this.urlSource);
      
      if(this.indexUrlActive < this.lastUrl.length-1) {    
        this.urlSource = this.lastUrl[++this.indexUrlActive];      
        this.onPlayningUrl.emit({url: this.urlSource, indexUrl: this.indexUrlActive});
      }
      else if((this.dateOfSettedUrls + this.timeRelativeToCanEmitError) > Date.now() && this.url.length) {
        this.indexUrlActive = 0;
      }
      else {
        // Comportamiento por defecto.         
        this.error.emit();
      }
    }
  }

  AddErrorLog(message: string) {
    this.errores = [message, ...this.errores].slice(0, 8);
  }

  handlerCurrentTime(time: {current: number, max: number}) {
    this.timeline = time.current;
    this.maxTimeLine = time.max;
  }
  handlerSetCallbackVideo(callback: ICommandVideoCallback) {
    this.commandVideo = callback;
    this.callbackVideo.emit(callback);
  }
  handlerOnChangeMuted() {
    this.muted = !this.muted;
  }
  handlerOnSkipBackward() {
    this.commandVideo("setTimeLine", this.commandVideo("getTimeLine") - 10);
  }
  handlerOnSkipForward() {
    this.commandVideo("setTimeLine", this.commandVideo("getTimeLine") + 10);
  }
  handlerOnChangePlay() {
    this.playning = !this.playning;
  }
  handlerOnIncreaseVolume() {
    this.volumen += 10;
    if(this.volumen > 100) this.volumen = 100;
  }
  handlerOnDescreaseVolume() {
    this.volumen -= 10;
    if(this.volumen < 0) this.volumen = 0;
  }
  handlerOnChangeTimeLine(time: number) {
    this.commandVideo("setTimeLine", time);
  }
  handlerOnChangeSliceBlackBorders() {
    // this.blackborders = !this.blackborders;
    if(++this.indexRelation >= this.relations.length) this.indexRelation = 0;
    this.setRelation(this.getRelation());
  }
  handlerOnChangeShowPicture() {
    this.showPicture = !this.showPicture
  }

  initSmootingIcon() {
    console.log("SMOOTING")
    if(this.idTimerChangePlay) clearTimeout(this.idTimerChangePlay);

    this.smootingIcon = true;
    this.idTimerChangePlay = setTimeout(() => {
      this.smootingIcon = false;
      this.idTimerChangePlay = null;
    }, 2000);
  }

  timerAutoChangeUrl: any;
  timer: any;
  handlerChangeLoading(loading: boolean) {
    if(loading) {
      // open splash
      clearTimeout(this.timer);
      
      // Mostrar splash
      this.timer = setTimeout(() => {
        if(loading) {
          this.showSplash = true;
          this.timer = null;

          setTimeout(() => {
            this.openingSplash = true;
          }, 100);
        }
      }, 2000)

      if(!this.loadedUrl && !this.timerAutoChangeUrl) {
        this.timerAutoChangeUrl = setInterval(() => {
          console.log("VIDEO PLAYER: Se ha tardado en obtener una respuesta desde la url: " + this.urlSource)
          this.handlerError()
        }, 10000);
      }
    }
    else {
      if(this.timerAutoChangeUrl) {
        clearTimeout(this.timerAutoChangeUrl);
        this.loadedUrl = true;
        this.timerAutoChangeUrl = null;
      }
      // close splash
      clearTimeout(this.timer);

      if(this.showSplash) {
        this.openingSplash = false; // esto hará el efecto de despanecer

        this.timer = setTimeout(() => { // cerrado!
          this.showSplash = false; // esto es usado por un *ngIf
          this.timer = null;
        }, 1000)
      }
    }

    this.loadingPlayer = loading;
    this.loading.emit(loading);
  }
}
