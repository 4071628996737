import { Injectable, EventEmitter, Output, OnInit } from '@angular/core';
import { of, Observable } from 'rxjs'
import { execute } from "apollo-link";
import { WebSocketLink } from "apollo-link-ws";
import { SubscriptionClient } from "subscriptions-transport-ws";
import gql from "graphql-tag";
import { environment } from '../../environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { LocalStorageService } from './local-storage.service';
import { ConsultasService } from './consultas.service';

export const SUBSCRIBE_CONTROL_REMOTO = gql`
subscription($Code:String){
  ControlPlay(Data:{
    Codigo:$Code
  }){
    Codigo
    Cedula
    Comando
    Url
    Timer
  }
}
`

export const SUBSCRIBE_PUBLICIDAD = gql`
subscription($Franquicia:String){
  FibexPubli(Data:{
    Franquicia:$Franquicia
  }){
    Franquicia
    time
    style
    sectorAd
    img{
      url
      id
    }
  }
}
`


@Injectable({
  providedIn: 'root'
})
export class SubscriptionGraphqlService {
  DeviceReport: any = []
  wsurl = environment.ApiGraphQlfp
  wsurlsub = environment.UrlSubscriptionsfp
  UltimoComando: string = "";
  Contenido: string = "";
  Timer: string = "";
  InitReporte: boolean = false;
  idUniqueSystem: any
  getWsClient: any;
  client: any;
  idOperador
  Token = ""

  DeviceMax: any

  public OpControl: EventEmitter<any> = new EventEmitter();
  public Publicidad: EventEmitter<any> = new EventEmitter();

  constructor(
    private _localStorageService: LocalStorageService,
    private _consultas: ConsultasService,
  ) {

    // this.idUniqueSystem = this._localStorageService.get('idUniqueSystem')
    this.idUniqueSystem = this._localStorageService.get('idUniqueSystem')
    this.InitSuscriptionControlRemoto(this.idUniqueSystem + '_C');

    //this.pruebitaChat = of('hola');}
    if (!sessionStorage.getItem('tokenSubscription')) {
      return;
    }
    this.setToken(environment.Token);


  }

  GetMax(Cedula: any) {
    try {

      this._consultas.GetMaxDevice(Cedula).then((ResMax: any) => {
        this.DeviceMax = ResMax.MaxDevice || 4
      }).catch((error: any) => { this.DeviceMax = 4; console.error(error) })

    } catch (error) {
      console.error(error)
    }
  }

  ChequeoDevice() {
    try {

      if (this.DeviceReport && this.DeviceReport.length > 0) {

        if (this.DeviceReport.length >= this.DeviceMax) {
          const DataComando = {
            Codigo: '',
            Cedula: '',
            Url: '',
            Comando: "Matate",
            Timer: Date.now().toString()
          }
          this.GetComandoControl(DataComando)
        }

      }

      this.InitReporte = false

    } catch (error) {
      console.error(error)
    }
  }

  GetReport(Data: any) {
    try {
      switch (Data.Comando) {
        case 'Estoy aqui':
          if (!this.DeviceReport.some((Dev: any) => Dev.Cedula === Data.Cedula)) {
            this.DeviceReport.push(Data)
          }
          break;
        case 'Reportence':

          if (!this.InitReporte) {
            const ComandoSend = {
              Codigo: Data.Codigo,
              Cedula: this.idUniqueSystem,
              Url: '',
              Comando: "Estoy aqui",
              Timer: Date.now().toString()
            }

            this._consultas.SendComandContr(ComandoSend)
          }

          break;
      }
    } catch (error) {
      console.error(error)
    }
  }


  GetComandoControl(Data) {
    try {

      if (this.UltimoComando === Data.Comando && this.Contenido === Data.Url && this.Timer === Data.Timer) {

      } else {
        this.UltimoComando = Data.Comando
        this.Contenido = Data.Url
        this.Timer = Data.Timer
        this.OpControl.emit(Data)
      }

    } catch (error) {
      console.error
    }
  }

  setToken(token: string) {
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    //this.idOperador =decodedToken.Data.idUser
    sessionStorage.setItem('tokenSubscription', (true).toString());
  }


  InitSuscriptionControlRemoto(Code: any) {
    try {

      const Datos = {
        Code: Code
      }

      this.createSubscriptionObservable(SUBSCRIBE_CONTROL_REMOTO, Datos).subscribe((eventData: any) => {
        const DataComando = eventData.data.ControlPlay
        this.GetComandoControl(DataComando)
      })

    } catch (error) {
      console.error(error)
    }
  }

  InitSuscriptionDeviceHermanos(Code: any, StartReport: boolean) {
    try {
      const Datos = {
        Code: Code
      }

      this.InitReporte = StartReport
      setTimeout(() => { this.ChequeoDevice() }, 60000);

      this.createSubscriptionObservable(SUBSCRIBE_CONTROL_REMOTO, Datos).subscribe((eventData: any) => {
        const DataComando = eventData.data.ControlPlay
        this.GetReport(DataComando)
      })

    } catch (error) {
      console.error(error)
    }
  }

  InitSuscriptionPublicidad(Franquicia: String) {
    try {

      const Datos = {
        Franquicia: Franquicia
        // Franquicia: "TEST"
      }

      this.createSubscriptionObservable(SUBSCRIBE_PUBLICIDAD, Datos).subscribe((eventData: any) => {
        const DataPublicidad = eventData.data.FibexPubli
        this.Publicidad.emit(DataPublicidad)
      })

    } catch (error) {
      console.error(error)
    }
  }

  createSubscriptionObservable(query: any, variables?: any) {
    try {
      //Me conecto al web socket
      this.getWsClient = function (Wsocket: any) {
        if (this.client != undefined) {
          return this.client
        } else {
          this.client = new SubscriptionClient(
            Wsocket, { reconnect: true },
          );
          return this.client;
        }
      }
      if (variables == undefined) {
        const link = new WebSocketLink(this.getWsClient(this.wsurlsub));
        return execute(link, { query: query });
      }
      else {
        const link = new WebSocketLink(this.getWsClient(this.wsurlsub));
        return execute(link, { query: query, variables: variables });
      }
    } catch (error) {
      console.error(error)
    }
  }
}
