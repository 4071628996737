import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ForgotpassRoutingModule } from './forgotpass-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { ForgotPasswordPageComponent } from './forgot-password-page.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
    ForgotpassRoutingModule
  ],
  declarations: [ForgotPasswordPageComponent],
})
export class ForgotpassModule { }
