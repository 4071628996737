import { AfterViewInit, Component, HostListener, OnInit, ViewChild } from "@angular/core";
import { ModalController, NavController } from "@ionic/angular";
import { ChannelsComponent } from "../../../app/pages/modal/channels/channels.component";
import { OTTService } from "../../services/ott.service";
import { IonSlides } from "@ionic/angular";
import { SwiperOptions } from "swiper";

@Component({
  selector: "app-grilla",
  templateUrl: "./grilla.component.html",
  styleUrls: ["./grilla.component.scss"],
})
export class GrillaComponent implements OnInit, AfterViewInit {
  _channel: boolean = false;
  AllCanales: any = []
  AllCategorias: any = []
  AllPaquetes: any = []
  scrHeight: number;
  scrWidth: number;
  view: boolean;
  phoneMode: boolean = false
  tableMode: boolean = false

  Planes: any = []
  PlanesAdultos: any = []
  CanalesCategorias: any = []
  Categorias: any = []
  CategoriaAdulto: any = []
  plan: any[] = []
  selected: any;
  currentPage = 1;
  pageOfItems: Array<any>;
  categoriesTitle: string[] = []

  @ViewChild('slide1',{'static':true}) slides: IonSlides;
  @ViewChild('slide2',{'static':true}) slides2: IonSlides;
  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any) {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;
    this.scrWidth >= 965 ? this.view = true : this.view = false;
    this.scrHeight >= 1000 && this.scrHeight > this.scrWidth ? this.tableMode = true : this.tableMode = false;
    this.scrHeight > this.scrWidth ? this.phoneMode = true : this.phoneMode = false;
  }

  slideOptions: SwiperOptions = {
    autoplay: true,
    // loop: true
  };

  constructor(
    public navCtrl: NavController,
    public ott: OTTService,
    private modalCtrl: ModalController
  ) {
    this.getScreenSize()
  }

  ngOnInit() {
    this.ott.getChannelsForInfo().then((data: any) => {
      this.AllCanales = data.Canales
      this.AllCategorias = data.Categorias
      this.AllPaquetes = data.Paquetes
      this.GetCategoriaCanales()
    });
  }
  ngAfterViewInit() { }


  GetInfoPlanes() {
    try {

      console.log(this.CanalesCategorias)

      const DataPaquetes: any = this.AllPaquetes.filter((FP: any) => !FP.Paquete.includes("ADULTOS"))
      const DataPaquetesAdultos: any = this.AllPaquetes.filter((FPA: any) => FPA.Paquete.includes("ADULTOS"))

      if (DataPaquetes && DataPaquetes.length > 0) {
        for (let index = 0; index < DataPaquetes.length; index++) {
          const Canales: any = this.CanalesCategorias.filter((FC: any) => FC.Paquete.includes(DataPaquetes[index].Id))
          this.Planes.push({
            Paquete: DataPaquetes[index].Paquete.replace("FIBEXPLAY", "IPTV"),
            Costo: DataPaquetes[index].Costo,
            Canales
          })
        }
        this.plan = this.Planes[0]
        this.selected = this.plan
        this.setListCategories(this.Planes[0])
      }

      if (DataPaquetesAdultos && DataPaquetesAdultos.length > 0) {
        for (let index = 0; index < DataPaquetesAdultos.length; index++) {
          const Canales: any = this.CanalesCategorias.filter((FC: any) => FC.Paquete.includes(DataPaquetesAdultos[index].Id))
          this.PlanesAdultos.push({
            Paquete: DataPaquetesAdultos[index].Paquete.replace("FIBEXPLAY", "IPTV"),
            Costo: DataPaquetesAdultos[index].Costo,
            Canales
          })
        }
      }

      this.GetInfoCategorias()
      this.GetCategoriaAdultos()

    } catch (error) {
      console.error(error)
    }
  }

  async GetInfoCategorias() {
    try {

      if (this.Planes && this.Planes.length > 0) {
        for (let iPlan = 0; iPlan < this.Planes.length; iPlan++) {
          await this.GetTotalCategorias(this.Planes[iPlan].Canales).then((ResTotal: any) => {
            this.Categorias.push({
              ...this.Planes[iPlan],
              Categorias: ResTotal
            })
          })
        }
        this.Categorias.map(category => category.Paquete = category.Paquete.replace("FIBEXPLAY", "IPTV"))
        console.log('this.Categorias', this.Categorias)
      }


    } catch (error) {
      console.error(error)
    }
  }

  GetCategoriaCanales() {
    try {

      if (this.AllCanales && this.AllCanales.length > 0) {
        for (let index = 0; index < this.AllCanales.length; index++) {
          const Categoria = this.AllCategorias.find((FC: any) => FC.Id === this.AllCanales[index].Categoria)
          this.CanalesCategorias.push({ ...this.AllCanales[index], NombreCategoria: Categoria.Categoria })
          if (index === this.AllCanales.length - 1) {
            this.GetInfoPlanes()
          }
        }
      }

    } catch (error) {
      console.error(error)
    }
  }

  GetCategoriaAdultos() {
    const Adultos: any = this.AllCategorias.find((FA: any) => FA.Categoria === "Adulto")

    /* if (Adultos && Adultos.length > 0) { */
      
      const Canales: any = this.CanalesCategorias.filter((FCC: any) => FCC.Categoria === Adultos.Id)
      
      this.CategoriaAdulto.push({
        Paquete: "FIBEXPLAY ADULTO",
        Canales
      })
      
    /* } */

  }

  GetTotalCategorias(Canales: any) {
    return new Promise(async (resolve: any, reject: any) => {
      try {
        let TotalByCategorias: any = []
        for (let index = 0; index < this.AllCategorias.length; index++) {
          const Total: any = Canales.filter((FT: any) => FT.Categoria === this.AllCategorias[index].Id)
          TotalByCategorias.push({ Categoria: this.AllCategorias[index].Categoria, Cantidad: Total.length })
          if (index === this.AllCategorias.length - 1) {
            resolve(TotalByCategorias)
          }
        }

      } catch (error) {
        console.error(error)
      }
    })
  }

  onChangePage(pageOfItems: Array<any>) {
    // update current page of items
    this.pageOfItems = pageOfItems;
  }

  slidePrev(slide: string) {
    if(slide === 'slide1') this.slides.slidePrev()
    if(slide === 'slide2') this.slides2.slidePrev()
    
  }
  slideNext(slide: string) {
    if(slide === 'slide1') this.slides.slideNext()
    if(slide === 'slide2') this.slides2.slideNext()
  }

  setListCategories(plan: any){
    plan.Canales.forEach(element => {
      
      if(this.categoriesTitle.length === 0) this.categoriesTitle.push(element.NombreCategoria)

      if(!this.categoriesTitle.includes(element.NombreCategoria)) this.categoriesTitle.push(element.NombreCategoria)

    });
  }
}

