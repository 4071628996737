export const environment = {
  production: false, // set TRUE before you build and release a prod version.
  // Set your app configurations here.
  // For the list of config options, please refer to https://ionicframework.com/docs/api/config/Config/
  Lic: '584120202020@c.us',
  LicChat: 'appServer1',
  apiGuia: "https://epgservice.thomas-talk.me/api/guide",
  ApiGraphQl: 'https://pha.thomas-talk.me/',
  UrlSubscriptions: "wss://pha.thomas-talk.me/subscriptions",
  ApiGraphQlfp: 'https://backend-fp.thomas-talk.me/',
  UrlSubscriptionsfp: "wss://backend-fp.thomas-talk.me/subscriptions",
  URLApiSSL: 'https://apissl.thomas-talk.me/',
  URLApiTLS: 'https://apitls.fibextelecom.net/',
  SecuryEncrypt: 'T0rNaDoK4tr1Na?RTgcNmhKU=',
  TokenSSL: '4p1_[51t3m4s]_f186@th0m4s',
  URLIP: 'https://login.thomas-talk.me/ip/',
  Token: 's1e1/c1r2e-2t.2a3r*3i4a',
  apiMaps: 'https://apiamaps.thomas-talk.me/api/v1/',
  //Token Encript
  apiPhP: 'https://login.thomas-talk.me/api/?',
  urlUploadService: 'https://img.thomas-talk.me/api/',
  tableDevice: 'tm_Device_FibexPlay',
  platform: 'FibexPlay',
  getDevice: 'GetLoginDevice',
  campoDevice: 'Mac',
  postDeviceMethod: 'RegisterLoginDevice',
  clearDeviceMethod: 'ClearLoginDevice',
  loginIdSslMethod: 'AllInfoClientByDNI',
  generateLicenseMethod: 'GenerateLicenciaApp',

  ApiGraphQl2: 'http://localhost:8087/',
  UrlSubscriptions2: "ws://localhost:4300/subscriptions",

  apiChatBot: "https://bot.thomas-talk.me/bot/tell-me",
  version: 'V2.3.26',

  promoUrl: "https://promotv.thomas-talk.me/",
  apiFibexPlay: 'https://login.thomas-talk.me/api/ci/?',

  apiResetPassword: "https://login.thomas-talk.me/api/pw/?",
  apiVerifyCedula: "https://login.thomas-talk.me/api/v/?",
  apiSecurityQuestions: 'https://login.thomas-talk.me/api/p_security/?',

  UserBackendFull: "Onur",
  PasswordBackendFull: "L4V1d43NsuPl3N1tud**=BghYjLaQeTB",
  TokenBackendFull: "h34BEVSzAijWRFeXBmRGA8FfarVHVLHZo_mfmS7c7TFUjlpMt0LFiI-NKy1tr89pJwpXoPiL2BemcwuDKIuJzeqii-eiZXilqyQYOcm_o1_SE29K5J76E1G-ZY--m0QHdSXvLrR2-dK6I8S7x24pbyzixoSgldSYCulhmooI5Ls0jAr4HS6a",
  NameBD: 'thomas_cobertura',
  ApiBackendThomas: 'https://dbfull.thomas-talk.me/api/',
  authdbFUll: 'Basic ' + btoa('Onur:L4V1d43NsuPl3N1tud**=BghYjLaQeTB'),
  tableLicense: 'tmLicesingDinamic',
  campoCi: 'Cedula',


  //OneSignal 
  one_signal_tbl: 'one_signal_push',
  type_specific_search:'find-any-info',
  type_specific_to_update:'update-info',
  field_specific_info:'abonado',
  field_specific_info2:'plataform',
  field_specific_info_to_update:'id_one_signal',
  

  config: {
    // iconMode: 'md',
    // mode: 'md'
    // iconMode: 'ios',
    // mode: 'ios'
    // preloadModules: true,
    // scrollPadding: false,
    // scrollAssist: true,
    autoFocusAssist: false,
    menuType: 'overlay'
  },
  // Set language to use.
  language: 'en',
  // Loading Configuration.
  // Please refer to the official Loading documentation here: https://ionicframework.com/docs/api/components/loading/LoadingController/
  loading: {
    spinner: 'circles'
  },
  // Toast Configuration.
  // Please refer to the official Toast documentation here: https://ionicframework.com/docs/api/components/toast/ToastController/
  toast: {
    position: 'bottom' // Position of Toast, top, middle, or bottom.
  },
  toastDuration: 3000, // Duration (in milliseconds) of how long toast messages should show before they are hidden.
  // Angular Google Maps Styles Config
  agmStyles: [
    {
      elementType: 'geometry',
      stylers: [
        {
          color: '#1d2c4d'
        }
      ]
    },
    {
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#8ec3b9'
        }
      ]
    },
    {
      elementType: 'labels.text.stroke',
      stylers: [
        {
          color: '#1a3646'
        }
      ]
    },
    {
      featureType: 'administrative.country',
      elementType: 'geometry.stroke',
      stylers: [
        {
          color: '#4b6878'
        }
      ]
    },
    {
      featureType: 'administrative.land_parcel',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#64779e'
        }
      ]
    },
    {
      featureType: 'administrative.province',
      elementType: 'geometry.stroke',
      stylers: [
        {
          color: '#4b6878'
        }
      ]
    },
    {
      featureType: 'landscape.man_made',
      elementType: 'geometry.stroke',
      stylers: [
        {
          color: '#334e87'
        }
      ]
    },
    {
      featureType: 'landscape.natural',
      elementType: 'geometry',
      stylers: [
        {
          color: '#023e58'
        }
      ]
    },
    {
      featureType: 'poi',
      elementType: 'geometry',
      stylers: [
        {
          color: '#283d6a'
        }
      ]
    },
    {
      featureType: 'poi',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#6f9ba5'
        }
      ]
    },
    {
      featureType: 'poi',
      elementType: 'labels.text.stroke',
      stylers: [
        {
          color: '#1d2c4d'
        }
      ]
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#023e58'
        }
      ]
    },
    {
      featureType: 'poi.park',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#3C7680'
        }
      ]
    },
    {
      featureType: 'road',
      elementType: 'geometry',
      stylers: [
        {
          color: '#304a7d'
        }
      ]
    },
    {
      featureType: 'road',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#98a5be'
        }
      ]
    },
    {
      featureType: 'road',
      elementType: 'labels.text.stroke',
      stylers: [
        {
          color: '#1d2c4d'
        }
      ]
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry',
      stylers: [
        {
          color: '#2c6675'
        }
      ]
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry.stroke',
      stylers: [
        {
          color: '#255763'
        }
      ]
    },
    {
      featureType: 'road.highway',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#b0d5ce'
        }
      ]
    },
    {
      featureType: 'road.highway',
      elementType: 'labels.text.stroke',
      stylers: [
        {
          color: '#023e58'
        }
      ]
    },
    {
      featureType: 'transit',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#98a5be'
        }
      ]
    },
    {
      featureType: 'transit',
      elementType: 'labels.text.stroke',
      stylers: [
        {
          color: '#1d2c4d'
        }
      ]
    },
    {
      featureType: 'transit.line',
      elementType: 'geometry.fill',
      stylers: [
        {
          color: '#283d6a'
        }
      ]
    },
    {
      featureType: 'transit.station',
      elementType: 'geometry',
      stylers: [
        {
          color: '#3a4762'
        }
      ]
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [
        {
          color: '#0e1626'
        }
      ]
    },
    {
      featureType: 'water',
      elementType: 'labels.text.fill',
      stylers: [
        {
          color: '#4e6d70'
        }
      ]
    }
  ]
  // //
}
