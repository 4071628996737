import { Component, OnInit,Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { LocalStorageService } from '../../../services/local-storage.service';
import { SafeUrl } from '@angular/platform-browser';


@Component({
  selector: 'app-remote-controller',
  templateUrl: './remote-controller.component.html',
  styleUrls: ['./remote-controller.component.scss'],
})
export class RemoteControllerComponent implements OnInit {
  @Input() data: any;
  public qrCodeDownloadLink: SafeUrl = "";
  public idUniqueSystem: any;
  public title;
  private intervalId: any;
  private Error: number = 0;

  constructor(
    private modalCtrl: ModalController,
    public localStorage: LocalStorageService,
  ) { }

  ngOnInit() {
    this.title - this.data.title;
    console.log(this.data);
    this.idUniqueSystem =  this.localStorage.get('idUniqueSystem')
  
  }

  onChangeURL(url: SafeUrl) {
    this.qrCodeDownloadLink = url;
  }

  closeModal() {
    this.modalCtrl.dismiss('cancel');
  }

}
