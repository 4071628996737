import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NavController } from '@ionic/angular';
import { UserAuthenticationService } from '../../services/user-authentication.service';
import { SubscriptionGraphqlService } from "../../services/subscription-graphql.service";
import gql from "graphql-tag";
import { LocalStorageService } from '../../services/local-storage.service';
import { VerifyInternetStatusService } from '../../services/verify-internet-status.service';

@Component({
  selector: 'app-favorites',
  templateUrl: './favorites.page.html',
  styleUrls: ['./favorites.page.scss'],
})

export class FavoritesPage implements OnInit {
  favorites: Array<any> = [];
  public onLine: boolean = true;
  public CedulaActual: any;
  public urlPagos: string = 'https://pagos.fibextelecom.net/pay?dni=';
  public secureUrl: string = '';
  constructor(
    public navCtrl: NavController,
    private _SubscriptionGraphqlService: SubscriptionGraphqlService,
    private _localStorageService: LocalStorageService,
    private _internetService: VerifyInternetStatusService,
  ) { 
    this.onLine = this._internetService.onLine;  
    this._internetService.internetState.subscribe((data: boolean) => { 
      this.onLine = data
      this._internetService.verifyConection(this.onLine, true);
    });
  }

  ngOnInit() {
    this._internetService.verifyConection(this.onLine, true);
    this.CedulaActual = this._localStorageService.get('CedulaActual');
    this.secureUrl = `${this.urlPagos}${this.CedulaActual.identidad}`;
  }

  editprofile() {
    this.navCtrl.navigateForward('edit-profile');
  }

}
