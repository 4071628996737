import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { LocalStorageService } from '../../../services/local-storage.service';

@Component({
  selector: 'app-pin-registration',
  templateUrl: './pin-registration.page.html',
  styleUrls: ['./pin-registration.page.scss'],
})
export class PinRegistrationPage implements OnInit {
  public registerPinForm: FormGroup;
  public changePinForm: FormGroup;
  public pinCreated: boolean = false;
  public pinActive: any;

  constructor(
    private modalCtrl: ModalController,
    private _localStorageService: LocalStorageService,
    private formBuilder: FormBuilder,
  ) { 

  }

  ngOnInit() {
    //this.modalCtrl.dismiss();
    /* this.registerPinForm = this.formBuilder.group({
      'pinRegister': [null, Validators.compose([
        Validators.required
      ])],
    }); */
    this.pinExist();
  }
  
  savePin() {
    this._localStorageService.set('pinRegister', this.registerPinForm.value.pinRegister)
    this.modalCtrl.dismiss('signup');
  }

  pinExist(){
    this.pinActive = this._localStorageService.get('pinRegister');
    if (this.pinActive !== null) {
      this.pinCreated = true;
      this.changePinForm = this.formBuilder.group({
        'prevPin': ['', Validators.compose([
          Validators.required,  Validators.pattern("^[0-9]*$")
        ])],
        'pinRegister': ['', Validators.compose([
          Validators.required,  Validators.pattern("^[0-9]*$")
        ])],
      });
    }else{
      this.registerPinForm = this.formBuilder.group({
        'pinRegister': ['', Validators.compose([
          Validators.required,  Validators.pattern("^[0-9]*$")
        ])],
      });
    }
  }

  async changePin(){
    const inputPrevPin = this.changePinForm.controls['prevPin'].value
    const inputNewPin = this.changePinForm.controls['pinRegister'].value
    if (this.pinActive == inputPrevPin) {
      this._localStorageService.set('pinRegister', inputNewPin)
      this.modalCtrl.dismiss('signup');
    }else{
      this.modalCtrl.dismiss('wrong')
    }
  }

  closeModal() {
    this.modalCtrl.dismiss('cancel');
  }

}
