import { Component, ElementRef, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { SLDP as ISLDP, SLDPPlayer } from '../../../types/SLDP';
import { ICommandVideoCallback } from '../video-player/video-player.component';

declare var SLDP: ISLDP;

@Component({
  selector: 'app-video-player-sldp',
  templateUrl: './video-player-sldp.component.html',
  styleUrls: ['./video-player-sldp.component.scss'],
})
export class VideoPlayerSldpComponent implements OnInit {
  @ViewChild("video_sldp", {static: true}) video_sldp: ElementRef; 
 show: boolean = true;

  @Input() volumen: number; // rango del 0 al 100
  @Input() url: string | null; // url del video
  @Input() blackborders: boolean; // eliminar border negros
  @Input() playning: boolean; // reproduciendo
  @Input() muted: boolean; // Muteado
  @Input() showPicture: boolean;

  @Output() onVolumen: EventEmitter<number> = new EventEmitter();
  @Output() callback: EventEmitter<ICommandVideoCallback> = new EventEmitter();
  @Output() currentTime: EventEmitter<{current: number, max: number}> = new EventEmitter();
  @Output() loading: EventEmitter<boolean> = new EventEmitter();
  @Output() error: EventEmitter<void> = new EventEmitter();
  @Output() closePicture: EventEmitter<void> = new EventEmitter();

  public loadingPlayer: boolean = true;
  private player: SLDPPlayer | null;
  private video: HTMLVideoElement | null;
  public idPlayer: string = "";
  // private listenner: any;
  // private listennerStartLoading: any;
  // private listennerEndLoading: any;
  
  constructor() { 
    this.volumen = 100;
    this.url = null;
    this.blackborders = true;
    this.player = null;
  }

  // life cicles of angular
  ngOnInit() {
    do {
      this.idPlayer = "sldp_player_id" + Math.round((Math.random() * 0xffffffff)).toString(16);
    } while(document.getElementById(this.idPlayer));

    this.video_sldp.nativeElement.id = this.idPlayer;
    setTimeout(() => {
      function callback(this: VideoPlayerSldpComponent, type: "getTimeLine"): number;
      function callback(this: VideoPlayerSldpComponent, type: "getMaxTimeLine"): number;
      function callback(this: VideoPlayerSldpComponent, type: "setTimeLine", v: number): void;
      function callback(this: VideoPlayerSldpComponent, type: "getTimeLine" | "getMaxTimeLine" | "setTimeLine", v?: number): number | void {
        switch(type) {
          case "getMaxTimeLine":
            return this.video ? this.video.duration : 0;
  
          case "getTimeLine":
            return this.video ? this.video.currentTime : 0;
  
          case "setTimeLine":
            this.video.currentTime = v || 0;
            break;
        }
      }
      
      this.InitVideoSLDP();
      this.callback.emit(callback.bind(this));
    }, 1000)
  }
  
  ngOnChanges() {
    this.InitVideoSLDP();
  }

  ngOnDestroy() {
    this.Destroy();
  }

  currentUrl: string | null = null;


  // player methods
  async InitVideoSLDP() {
    if(this.show) {
      if(!this.player) {
        this.player = SLDP.init({
          container:          "player-sldp-container-id",
          stream_url:         this.currentUrl = (this.url || ""),
          buffering:          500,
          autoplay:           this.playning,
          muted:              this.muted,
          controls:           false,
          latency_adjust_method: "fast-forward",
          latency_tolerance:   0,
          adaptive_bitrate:    true,
          reconnects: 100000
          // width:               'parent',
          // height:              'parent',
          // pause_timeout:       0,
          // controls:            true,
          // vu_meter:            {api: 'AudioWorklet', type: 'input', container: 'vu-meter', mode: 'peak', rate: 16}
        });
  
        // set Callbacks
        this.player.setCallbacks({
          onConnectionStarted: this.onConnectionStarted,
          onConnectionEstablished: this.onConnectionEstablished,
          onPlay: this.onPlay,
          onPause: this.onPause,
          onVolumeSet: this.onVolumeSet,
          onError: this.onError,
          onConnectionClosed: this.onConnectionClosed,
          onChangeRendition: this.onChangeRendition,
          onChangeRenditionComplete: this.onChangeRenditionComplete,
          onLatencyAdjustSeek: this.onLatencyAdjustSeek,
          onLowBuffer: this.onLowBuffer
        });
  
        this.player.setVolume(this.volumen);
        this.video = this.video_sldp.nativeElement.querySelector("video");
        (this.video as any).playsInline = true;
        (this.video as any).loop = true;

        this.video.addEventListener("timeupdate", (ev) => {
          this.currentTime.emit({current: this.video.currentTime, max: this.video.duration});
        })
        this.video.addEventListener("waiting", (ev) => {
          this.loading.emit(true);
          this.loadingPlayer = true;
        })
        this.video.addEventListener("canplaythrough", (ev) => {
          this.loading.emit(false);
          this.loadingPlayer = false;
        })
        this.video.addEventListener("volumechange", (ev) => {
          this.onVolumen.emit(this.video.volume*100);
        })
        this.video.addEventListener("leavepictureinpicture", (ev) => {
          this.closePicture.emit();
        })

        setTimeout(() => {
          this.video.click();
        }, 2000);
      }
      else {
        
        if(this.player.getVolume() !== this.volumen) {
          this.player.setVolume(this.volumen);
          if(this.player.getVolume() !== this.volumen) {
            this.onVolumen.emit(this.player.getVolume());
          }
        }
        if(this.currentUrl !== this.url) {
          this.loadingPlayer = true;
          this.player.setStreamURL(this.url);
          this.currentUrl = this.url;
        }
        
        if(this.video) {
          if(this.video.paused !== !this.playning) {
            if(this.playning) this.player.play();
            else this.player.pause();
          }
          if(this.video.muted !== this.muted) this.video.muted = this.muted;
        }
      }
      
      if(this.showPicture) {
        if((document as any).pictureInPictureElement !== this.video) {
          if((document as any).pictureInPictureElement) await (document as any).exitPictureInPicture();
          if(this.show && this.showPicture) (this.video as any).requestPictureInPicture();
        }
      }
      else {
        if((document as any).pictureInPictureElement === this.video) {
          (document as any).exitPictureInPicture();
        }
      }
    }
    else this.Destroy();

    if(this.video) {
      this.video.style.objectFit = this.blackborders ? '' : 'fill';
    }
  }

  Destroy() {
    if(this.player) {
      this.player.setVolume(0);
      this.player.destroy(() => console.log("Player SLDP Destruido correctamente!"));
      this.player = null;
    }
  }

  Log = (...info: unknown[]) => {
    console.log.apply(console, info);
  }


  // Calbacks of player SLDP
  private onConnectionStarted = ( url ) => {
    this.Log("onConnectionStarted:", url);
  }
  private onConnectionEstablished = ( streams ) => {
    this.Log("onConnectionEstablished, streams:", JSON.stringify(streams));
  }
  private onPlay = () => {
    this.Log("onPlay");
  }
  private onPause = () => {
    this.Log("onPause");
  }
  private onVolumeSet = ( vol: number ) => {
    this.Log("onVolumeSet:", vol);
  }
  private onError = ( error ) => {
    this.error.emit();
    this.Log('onError:', error);
  }
  private onConnectionClosed = () => {
    this.Log("onConnectionClosed");
    if(this.player && this.playning) {
      // setTimeout(() => {
        this.player.play();
      // }, 500);
    }
  }
  private onChangeRendition = ( rendition, streamName ) => {
    this.Log("onChangeRendition:", rendition, streamName);
  }
  private onChangeRenditionComplete = ( rendition, streamName ) => {
    this.Log("onChangeRenditionComplete:", rendition, streamName);
  }
  private onLatencyAdjustSeek = ( from, to ) => {
    this.Log("onLatencyAdjustSeek: seek from " + from + " to " + to );
  }
  private onLowBuffer = () => {
    this.Log("onLowBuffer");
  }

}
