import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EncryptService {
  secretKey = "YourSecretKeyForEncryption&Descryption";
  key: string = 'T0rNaDoK4tr1Na?RTgcNmhKU='

  constructor() { }

  encrypt(value: string) {
    try {

      if (value) {
        var _encrypt = CryptoJS.AES.encrypt(value, this.secretKey.trim()).toString();
        return _encrypt
      } else {
        return value
      }

    } catch (error) {
      console.error(error)
      return value
    }
  }

  decrypt(textToDecrypt: any) {
    try {

      if (textToDecrypt) {
        var _descrypt = CryptoJS.AES.decrypt(textToDecrypt, this.secretKey.trim()).toString(CryptoJS.enc.Utf8)
        return _descrypt;
      } else {
        return textToDecrypt
      }

    } catch (error) {
      console.error(error)
      return textToDecrypt
    }
  }

  public EncrypObj(obj: any) {
    const newObj: any = {};
    for (let keyName in obj) {
      if (obj[keyName] instanceof Array || obj[keyName] instanceof Object) {
        obj[keyName] = this.EncrypObj(obj[keyName]);
      }
      else newObj[keyName] = String(this.encrypt(obj[keyName]));
    }
    return newObj;
  }

  EncryptSSL(Texto: string) {
    return CryptoJS.AES.encrypt(Texto, environment.SecuryEncrypt, {
      keySize: 16,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    }).toString()
  }

  public encryptII(str: string) {
    let encrypted = CryptoJS.AES.encrypt(str, this.key, {
        keySize: 16,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
    });
    return encrypted.toString();
  }

  public decryptII(textToDecrypt: any) {
    try {

      if (textToDecrypt) {
        let _descrypt = CryptoJS.AES.decrypt(textToDecrypt, this.key).toString(CryptoJS.enc.Utf8)
        return _descrypt;
      } else {
        return textToDecrypt
      }

    } catch (error) {
      console.error(error)
      return textToDecrypt
    }
  }
}
