import {AfterViewInit, Directive, ElementRef, Input} from '@angular/core';

@Directive({
    selector: '[appImage]'
})
export class ImageDirective implements AfterViewInit {

    @Input() src;

    constructor(private imageRef: ElementRef) {
      console.log("DIRECTIVA ACTIVA")
    } 

    ngAfterViewInit(): void {
        const img = new Image();
        img.onload = () => {
            this.setImage(this.src);
        };

        img.onerror = () => {
            // Set a placeholder image 
            this.setImage('assets/img/LogoFibexPlay2.png');
        }; 

        img.src = this.src;
    }
 
    private setImage(src: string) {
        this.imageRef.nativeElement.setAttribute('src', src);
    }
}