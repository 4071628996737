import { Component, OnInit, EventEmitter, Input, Output, ViewChild, ElementRef } from '@angular/core';
import { DomSanitizer, SafeResourceUrl, SafeUrl, SafeStyle } from '@angular/platform-browser';

@Component({
  selector: 'app-menu-player',
  templateUrl: './menu-player.component.html',
  styleUrls: ['./menu-player.component.scss']
})
export class MenuPlayerComponent implements OnInit {
  @ViewChild("progress_line", { static: true }) progress_line!: ElementRef<HTMLDivElement>;
  
  // entrada
  @Input() show: boolean=true; // mostrar botones del player
  @Input() playning: boolean; // reproduciendo
  @Input() muted: boolean; // silenciado
  @Input() volume: number; // volumen actual
  @Input() timeLine: number; // tiempo actual del video
  @Input() maxTimeLine: number; // tiempo maximo del video
  @Input() fullscreen: boolean; // pantalla completa
  @Input() sliceBlackBorders: boolean; // Rellenar pantalla (eliminar bordes negros)
  @Input() showPicture: boolean; // modo picture
  @Input() favorite: boolean; // canal (o película) favorito 
  @Input() locked: boolean; // canal (o película) bloqueado (control parental)
  @Input() isTV: boolean = false; // opcional
  @Input() isMobile: boolean = false; // opcional
  @Input() isBrowser: boolean = false; // opcional
  @Input() isLive: boolean = false; // opcional
  @Input() url: string = "";
  @Input() displayRelation: string = "";
  // salida
  @Output() onChangePlay: EventEmitter<boolean> = new EventEmitter();
  @Output() onChangeMuted: EventEmitter<boolean> = new EventEmitter();
  @Output() onChangeVolume: EventEmitter<number> = new EventEmitter();
  @Output() onChangeTimeLine: EventEmitter<number> = new EventEmitter();
  @Output() onChangeFullscreen: EventEmitter<boolean> = new EventEmitter();
  @Output() onChangeSliceBlackBorders: EventEmitter<boolean> = new EventEmitter();
  @Output() onChangeShowPicture: EventEmitter<boolean> = new EventEmitter();
  @Output() onChangeFavorite: EventEmitter<boolean> = new EventEmitter();
  @Output() onChangeLocked: EventEmitter<boolean> = new EventEmitter();
  // salida (sin valores)
  @Output() onSkipBackward: EventEmitter<void> = new EventEmitter();
  @Output() onSkipForward: EventEmitter<void> = new EventEmitter();
  @Output() onIncreaseVolume: EventEmitter<void> = new EventEmitter();
  @Output() onDescreaseVolume: EventEmitter<void> = new EventEmitter();
  @Output() onShowInfo: EventEmitter<void> = new EventEmitter();
  @Output() onChangeViewType: EventEmitter<void> = new EventEmitter();
  @Output() nextChannel: EventEmitter<void> = new EventEmitter();
  @Output() backChannel: EventEmitter<void> = new EventEmitter();
  
  get progressChanging() {
    return this.progress;
  };
  set progressChanging(v: number) {
    this.progress = v;
    this.onChangeTimeLine.emit(Number(v) * this.maxTimeLine / 100);
  }

  // _progress: number = 0;
  // isProgressChanging: boolean = false;
  
  // PausePlayer(ev) {
  //   console.log("PAUSE MENU")
  //   this.isProgressChanging = true;
  // }

  // ChangePlayerProgress(progress: string | number) {
  //   if(this.isProgressChanging) {
  //     console.log("CHANGED", progress)
  //     this.onChangeTimeLine.emit(Number(progress) * this.maxTimeLine / 100);
  //     // setTimeout(() => this.isProgressChanging = false, 100);
  //   }
  // }

  // EndChange(ev) {
  //   console.log("END CHANGE:", ev);
  // }

  // Obtener duracion del video en formate de string (ej: "01:25")
  videoDuration: string = "00:00";
  progress: number = 0;
  maxProgress: number = 0;
  timeLineLive: {time: number, url: string} | null = null;
  
  constructor(public _sanitizer: DomSanitizer) { }

  ngOnInit() {
    this.checkDisplayMenu();
  }


  hideMenu: boolean = false;
  timerHide: NodeJS.Timer | null = null;

  checkDisplayMenu() {
    if(this.show) {
      if(this.timerHide) {
        this.timerHide = null;
        clearTimeout(this.timerHide);
      }
      this.hideMenu = false;
    }
    else if(!this.hideMenu && !this.timerHide) {
      this.timerHide = setTimeout(() => {
        this.hideMenu = true;
        this.timerHide = null;
      }, 1000);
    }

  }
  
  ngOnChanges() {
    this.checkDisplayMenu();

    if(this.maxTimeLine === Infinity) {
      // Cuando se recibe infinito es porque se trata de una reproduccion en vivo (duracion indeterminada)
      if(!this.timeLineLive || (this.timeLineLive.url !== this.url)) {
        this.timeLineLive = {time: 0, url: this.url};
      }
      
      if(this.timeLine > this.timeLineLive.time) {
        this.timeLineLive.time = this.timeLine;
      }

      this.maxProgress = this.timeLineLive.time;
    }
    else {
      // en caso que no, simplemente asignamos el valor máximo
      this.maxProgress = this.maxTimeLine;
    }

    this.progress = this.timeLine / this.maxProgress * 100;

    if(Number.isNaN(this.progress)) {
      this.progress = 0;
    }

    const segundos = Math.floor(this.timeLine) % 60; // minutos
    const minutes = Math.floor(this.timeLine / 60) % 60; // minutos
    const hours = Math.floor(this.timeLine / 60 / 60); // horas
    
    this.videoDuration = (hours ? (hours.toString().padStart(2, "0") + ":") : "") + minutes.toString().padStart(2, "0") + ":" + segundos.toString().padStart(2, "0")
  }

  private lastProgress = -1;
  private safeProgress: any;
  getWidthStyleProgress() {
    if(this.lastProgress !== this.progressChanging) {
      this.lastProgress = this.progressChanging;
      this.safeProgress = this._sanitizer.bypassSecurityTrustStyle('width: ' + this.progressChanging + "%;" + (this.elementProgress ? "transition: 0s" : ""));
    }
    return this.safeProgress;
  }
  
  // Adelantar
  skipBackward() {
    this.onSkipBackward.emit();
  }
  
  // Retroceder
  skipForward() {
    this.onSkipForward.emit();
  }

  private playningBeforeChangeProgress: boolean = false;
  private elementProgress: HTMLDivElement | undefined;
  private reference: number = 0;
  InitProgressChange(ev: MouseEvent) {
    if(ev.buttons & 0x1 && !this.elementProgress) { // Click principal?
      this.playningBeforeChangeProgress = this.playning;
      this.elementProgress = ev.currentTarget as HTMLDivElement;
      this.onChangePlay.emit(false);
      const reference = ++this.reference;


      /** AL mover el mouse */
      var moveEvent = (ev: MouseEvent) => {
        if(ev.buttons & 0x1) {
          if(this.elementProgress) {
            const rect = this.elementProgress.getBoundingClientRect();
            const width = rect.width;
            const MouseX = ev.clientX - rect.left;
            console.log("mouseReference:", this.elementProgress);
            console.log("width:", width);
            console.log("MouseX:", MouseX);
            
            const time = MouseX / width * this.maxTimeLine;
            const timeApply = time < 0 ? 0 : (time >= this.maxTimeLine ? (this.maxTimeLine - 0.1) : time);
  
            this.timeLine = timeApply;
            this.onChangeTimeLine.emit(timeApply);
          }
        }
        else upEvent(ev);
      }
      /** Al levandar el click */
      var upEvent = (ev: MouseEvent) => {
        document.removeEventListener("mousemove", moveEvent);
        document.removeEventListener("mouseup", upEvent);
        if(this.playningBeforeChangeProgress) this.onChangePlay.emit(this.playningBeforeChangeProgress);
        delete this.elementProgress;
      }

      document.addEventListener("mousemove", moveEvent);
      document.addEventListener("mouseup", upEvent)
      moveEvent(ev);
    }
  }
}
