import { Injectable, EventEmitter } from '@angular/core';
import { AlertController, NavController } from '@ionic/angular';
import { Network } from '@awesome-cordova-plugins/network/ngx';


@Injectable({
  providedIn: 'root'
})
export class VerifyInternetStatusService {
  public onLine: boolean = true;
  public internetState: EventEmitter<any> = new EventEmitter<any>()
  public isConectFibex: EventEmitter<boolean> = new EventEmitter<boolean>()
  constructor(
    public alertCtrl: AlertController,
    private navCtrl: NavController,
    private network: Network,
  ) {
    this.onLine = navigator.onLine;
    try {
      /* this.ValidateConectFibex().then((Datos: any) => {
        if (Datos.connection.autonomous_system_organization == "CORPORACION FIBEX TELECOM, C.A.") {
          this.isConectFibex.emit(true);
        } else {
          this.isConectFibex.emit(false);
        }
      }).catch(err => console.error(err)) */
    } catch (error) {
      this.isConectFibex.emit(true);
    }



    if (this.network.type != null) {
      if (this.network.type === this.network.Connection.NONE) {
        this.onLine = false;
        this.internetState.emit(false);
      }
    }
  }

  initService() {
    try {
      window.addEventListener('online', (event) => this.updateOnlineStatus());
      window.addEventListener('offline', (event) => this.updateOnlineStatus());
    } catch (error) {

    }
  }

  async ValidateConectFibex() {
    return new Promise((resolve, reject) => {
      const axios = require('axios');

      axios.get('https://ipgeolocation.abstractapi.com/v1/?api_key=f646ed5a8a10495997ca02a57ec3406c')
        .then(response => {
          resolve(response.data);
        })
        .catch(error => {
          reject(error);
        });
    })

  }

  updateOnlineStatus() {
    if (this.network.type != null) {
      if (this.network.type === this.network.Connection.NONE) {
        this.onLine = false;
        this.internetState.emit(false);
      }
      return;
    }
    /* */
    this.onLine = navigator.onLine
    this.internetState.emit(navigator.onLine);
  }

  verifyConection(isOnline: boolean, goBack: boolean = false) {
    if (!isOnline) {
      this.displayAlert();
      if (goBack) {
        this.navCtrl.back();
      }

    }
  }

  async displayAlert() {
    const alert = await this.alertCtrl.create({
      header: 'No tiene internet',
      message: 'Conectese a internet para poder usar las funciones de la Aplicación.',
      buttons: [
        {
          text: 'Ok',
          role: 'cancel',
          cssClass: 'secondary',
        }
      ]
    });

    await alert.present();
  }
}
