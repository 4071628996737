import { Component, Input, OnInit } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
})
export class PopoverComponent implements OnInit {
  @Input() ListCDN:any;   @Input() option=2;  @Input() CDNActual:any;
  constructor(public popover: PopoverController) { 
  }

  ngOnInit() {}

  logout(id:number){
    this.popover.dismiss(id);
  }
  returnCDN(server:any){
    this.popover.dismiss(server)
  }
}
